import React from 'react';
import { FaFacebook, FaInstagram, FaLinkedin, FaTwitter, FaYoutube } from "react-icons/fa";
import { Link } from "react-router-dom";
const Footer = () => {
  const footerSections = [
    {
      title: "TEKNOPARK",
      links: [{ name: "Firmalarımız", slug: "/firmalarımız" }, { name: "Nasıl Başvurulur?", slug: "/nasil-basvurulur" },  { name: "Eğitim Başvuru", slug: "/egitim-basvuru" } , { name: "Destekler", slug: "/destekler" },  { name: "Haberler", slug: "/haberler" }]
    },
    {
      title: "KURUMSAL",
      links: [{ name: "Misyon & Vizyon", slug: "/misyon-vizyon" }, { name: "Hedefler", slug: "/hedefler"},  { name: "Şirket Yapısı", slug: "/sirket-yapisi"}, { name: "Mevzuat", slug: "/mevzuat"},  { name: "Yönetim Kurulu", slug: "/yönetim-kurulu"}, { name: "Yönetim Kadrosu", slug: "yönetim-kadrosu"}]
    },
    {
      title: "EKOSİSTEM",
      links: [{ name: "Teknopark Akademi", slug: "/teknopark-akademi" }, { name: "Teknopark Çocuk", slug: "/teknopark-cocuk" }, { name: "Teknopark Kuluçka", slug: "/teknopark-kulucka" },  { name: "Teknopark Blog", slug: "/teknopark-blog" }, { name: "Teknopark Game Hub", slug: "/tekno-gamehub" }]
    },
    {
      title: "KARİYER",
      links: [{ slug:"https://rcvs.trakyateknopark.com.tr/on-basvuru-formu", name:"Teknopark Başvuru"},{ slug:"https://rcvs.trakyateknopark.com.tr", name:" İş Başvurusu"}, "İş Başvurusu"]
    },
    {
      title: "Linkler",
      links: ["Kullanım Koşulları", "Gizlilik Politikası", "KVKK"]
    }
  ];

  return (
    <footer className="bg-primary-blue-dark text-white mt-10 ">
      <div className="sm:px-12 mx-auto px-4 py-8 container">
        <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-6 gap-8">
          {footerSections.map((section, index) => (
            <div key={index} className="mb-4 md:mb-0">
              <h3 className="font-semibold text-white text-sm mb-3">{section.title}</h3>
              <ul>
                {section.links.map((link, linkIndex) => (
            
                  <li key={linkIndex} className="text-sm hover:text-white">      <Link to={link.slug} >{link.name}</Link></li>
                ))}
              </ul>
            </div>
          ))}
          <div className="col-span-2 md:col-span-1">
            <h3 className="font-semibold mb-3">İLETİŞİM</h3>
            <p className="text-sm mb-2">(0284) 214 94 84</p>
            <p className="text-sm mb-2">Trakya Teknopark<br />Trakya Üniversitesi Ayşekadın Yerleşkesi<br />22100, Edirne</p>
            <p className="text-sm mb-4">iletisim@trakyateknopark.com.tr</p>
            <div className="flex space-x-4">
              <FaFacebook size={20} />
              <FaInstagram size={20} />
              <FaLinkedin size={20} />
              <FaTwitter size={20} />
              <FaYoutube size={20} />
            </div>
          </div>
        </div>
      </div>
      <div className="border-t border-gray-700 bg-white mt-8 py-4 px-4 sm:px-48 sm:py-4 h-full flex flex-col md:flex-row justify-evenly items-center">
        <div className="mb-4 md:mb-0 flex-shrink-0">
          <img src="/logo-dark.svg" alt="Trakya Teknopark" className="h-8" />
        </div>
        <p className="text-sm text-gray-400 mb-4 md:mb-0 md:flex-1 text-center">© 2024 Trakya Teknopark. Tüm hakları saklıdır.</p>
        <a href="https://brave.icu/" target="_blank" rel="noreferrer" className="">
        <img src="/brave-logo.svg" alt="Brave" className="h-8 flex-shrink-0" />
        </a>
      </div>
    </footer>
  );
};

export default Footer;
