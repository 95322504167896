import axiosClient from "../libs/axios";

export const getSingleCompany = async (id) => {
    const response = await axiosClient.get("/companies/" + id);
    return response.data;
};


export const postCompanies = async (data) => {
    const response = await axiosClient.post("/companies", data);
    return response.data;
  };

export const putCompanies = async (data,id) => {
    const response = await axiosClient.put("/companies/" + id, data); 
    return response.data;
};

export const deleteCompanies = async(id) => {
    const response = await axiosClient.delete("/companies/" + id)
    return response.data
}


