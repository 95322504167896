import React from "react";
import Footer from '../../mainComponents/footer';
import HeaderAll from '../../mainComponents/header-all/index';

function ApplicationProcess() {
  return (
    <React.Fragment>
      <HeaderAll />
      <div className="bg-primary-blue flex items-center h-12 w-full">
        <div className="pl-4 sm:pl-8 md:pl-16 lg:pl-32 text-xs text-white font-semibold">
          Anasayfa - Teknopark - Firmalarımız
        </div>
      </div>

      <div className="bg-how-apply bg-cover bg-center w-full h-60 sm:h-72 lg:h-80 flex justify-center items-center relative">
        <div className="w-full h-full absolute bg-primary-blue/30 inset-0 z-10" />
        <p className="text-white font-light text-lg sm:text-2xl lg:text-3xl w-[90%] sm:w-[80%] lg:w-[60%] flex flex-col justify-center items-center absolute z-10 text-center px-4">
          Trakya Teknopark
          <span className="font-bold text-2xl sm:text-4xl lg:text-5xl mt-2">Nasıl Başvurulur?</span>
        </p>
      </div>

      <div className="py-8 sm:py-11 w-full">
        <div className="grid grid-cols-1 gap-8 sm:gap-16 lg:grid-cols-2 lg:gap-24 px-4 sm:px-8 lg:px-16">
          <div className="flex flex-col gap-6 sm:gap-8 lg:gap-10">
            <h1 className="text-xl sm:text-2xl lg:text-3xl font-light text-primary-blue">
              Başvuru <span className="font-semibold">Genel Bilgiler</span>
            </h1>
            <p className="text-sm sm:text-base lg:text-lg">
              Edirne Teknoloji Geliştirme Bölgesi kapsamında, gerek Bölgenin gerekse ilgili mevzuatın getirdiği avantajlardan faydalanarak faaliyet göstermek isteyen bütün girişimci firmalar bu bölümde detaylı olarak açıklanan başvuru prosedürü tamamlamak durumundadır.<br /><br />
              Başvuru prosedürü girişimcileri istenilen bilgi ve belgeleri en kısa ve en efektif şekilde tamamlamalarına yönelik olarak planlanmıştır. Bu kapsamda Edirne Teknoloji Geliştirme Bölgesi’nde yer almak isteyen girişimci firmaların başvurularının yapılmasından Trakya Teknopark’ta faaliyete başlamalarına kadar geçen süreç yan taraftaki linkler içerisinde ayrıntılı olarak açıklanmaktadır.<br /><br />
              Girişimci firmaların statüsünden, finansal kaynaklarından ve büyüklüklerinden bağımsız olarak her büyüklük ve çeşitte kurulmuş olan girişimci firmaların eşit ve objektif bir şekilde süreçleri tamamlamaları amacıyla bu süreçlerde uygulanacak prosedürler ve bu süreçlerde kullanılan formlar oluşturulmuştur.
            </p>
          </div>
          <div className="flex flex-col gap-6 sm:gap-8 lg:gap-10">
            <h1 className="text-primary-blue font-semibold text-xl sm:text-2xl lg:text-3xl">Süreçler</h1>
            <div className="flex flex-col gap-6 sm:gap-8 lg:gap-10">
              {['Ön Başvuru', 'Esas Başvuru', 'Değerlendirme Süreci'].map((process, index) => (
                <div key={index} className="border-t-2 border-gray-200 flex pt-4 sm:pt-6 justify-between px-4 sm:px-5">
                  <p className="text-primary-blue text-sm sm:text-base lg:text-lg">{`${index + 1}. ${process}`}</p>
                  <a className="text-primary-blue font-semibold text-sm sm:text-base lg:text-lg" href="#">Buraya Tıklayın</a>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>

      <div className="bg-howtoapply bg-contain bg-no-repeat 
      bg-center w-full h-[800px] sm:h-[1150px] md:h-[1600px] lg:h-[2800px]"></div>
      <Footer />
    </React.Fragment>
  );
}

export default ApplicationProcess;
