import React from "react";
import HeaderAll from "../../mainComponents/header-all";
import Footer from "../../mainComponents/footer";

function teknoparkChild() {
  return (
    <React.Fragment>
      <HeaderAll />
      <div className="bg-primary-blue flex items-center h-12 w-full">
        <div className="pl-4 sm:pl-8 md:pl-16 lg:pl-32 text-xs text-white font-semibold">
          {" "}
          Anasayfa - Teknopark - Teknopark Çocuk
        </div>
      </div>

      <div className="bg-how-apply bg-no-repeat bg-cover w-full h-60 sm:h-72 flex justify-center items-center relative">
        <div className="w-full h-full absolute bg-primary-blue/50 inset-0 z-10">
          &nbsp;
        </div>
        <p className="text-white font-light text-lg sm:text-xl text-center w-[300px] sm:w-[450px] flex flex-col justify-center items-center absolute z-10">
          Trakya Teknopark
          <span className="font-bold text-2xl sm:text-3xl">
            {" "}
            Teknopark Çocuk
          </span>
        </p>
      </div>

      <div className="container mx-auto  py-11">
        <div className="flex flex-col gap-8">
          <h1 className=" text-2xl font-light text-primary-blue">
            Teknopark Çocuk
          </h1>
          <div className="text-justify">
            Teknopark Çocuk, dijital dünyanın gelecek temsilcilerini yetiştirme
            amacıyla yola çıkarak, çocuklara analitik bakış açısı sağlamaktadır.
            Bilim, sanat ve teknolojinin iç içe geçtiği eğitimlerle inovasyon
            dünyasının kapılarını çocuklara açıyoruz. Bütün çocuklara
            eğitimlerimize eşit erişim imkânı sağlamak üzere çıktığımız bu
            yolda, geleceğin inovasyon ve ar-ge liderlerini hazırladığımız
            programlarla ekosistem içerisine dahil ediyoruz.
          </div>
        </div>
        <div className="mt-8 flex flex-col gap-8">
          <h1 className=" text-2xl font-light text-primary-blue">Fırsatlar</h1>
          <ul className="list-disc pl-8">
            <li>Robotik Kodlama</li>
            <li>Elektronik</li>
            <li>3D Tasarım</li>
            <li>Yazılım</li>
            <li>Ardunio</li>
          </ul>
        </div>
      </div>

      <Footer />
    </React.Fragment>
  );
}

export default teknoparkChild;
