import React from "react";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";
const iconMotion = {
  rest: {
    x: 100,
    transition: {
      duration: 0.3,
      ease: "easeInOut",
    },
  },
  hover: {
    x: 0,
    transition: {
      duration: 0.3,
      ease: "easeInOut",
    },
  },
};

const AnimatedLink = motion(Link);

export default function QuickLinks() {
  return (
    <motion.div
      initial="rest"
      whileHover="hover"
      animate="rest"
      className="absolute right-0 top-1/4 z-50 pr-5 flex cursor-pointer flex-col gap-3 overflow-hidden lg:top-1/3"
    >
      <AnimatedLink
        variants={iconMotion}
        to="https://rcvs.trakyateknopark.com.tr/"
        className="flex cursor-pointer items-center gap-2"
      >
        <div className="flex h-10 w-10 items-center justify-center rounded-full border-[1px] border-gray-500 bg-white">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width={20}
            height={20}
            fill="none"
          >
            <path
              fill="#081027"
              d="M17 2h-2V1a1 1 0 0 0-2 0v1H7V1a1 1 0 0 0-2 0v1H3a3 3 0 0 0-3 3v12a3 3 0 0 0 3 3h14a3 3 0 0 0 3-3V5a3 3 0 0 0-3-3Zm1 15a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1v-7h16v7Zm0-9H2V5a1 1 0 0 1 1-1h2v1a1 1 0 0 0 2 0V4h6v1a1 1 0 0 0 2 0V4h2a1 1 0 0 1 1 1v3Z"
            />
          </svg>{" "}
        </div>
        <div className="text-xs text-white">
          Toplantı Odası
          <br />
          Rezervasyon
        </div>
      </AnimatedLink>
      <AnimatedLink
        variants={iconMotion}
        to="https://rcvs.trakyateknopark.com.tr/"
        className="flex cursor-pointer items-center gap-2"
      >
        <div className="flex h-10 w-10 items-center justify-center rounded-full border-[1px] border-gray-500 bg-white">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width={17}
            height={22}
            fill="none"
          >
            <path
              fill="#081027"
              d="M6.007 6.63c.634 0 1.144-.51 1.144-1.145 0-.634-.51-1.144-1.144-1.144-.635 0-1.145.51-1.145 1.144 0 .635.51 1.145 1.145 1.145Z"
            />
            <path
              fill="#081027"
              fillRule="evenodd"
              d="M8.307 9.123c0-1.224-1.031-2.108-2.3-2.108-1.27 0-2.301.884-2.301 2.108v.578c0 .102.045.204.113.272.068.068.17.114.272.114h3.831a.396.396 0 0 0 .272-.114.396.396 0 0 0 .113-.272v-.578Zm-4.624 3.344a.85.85 0 0 1 .85-.85h7.934a.85.85 0 1 1 0 1.7H4.533a.85.85 0 0 1-.85-.85Zm0 3.4a.85.85 0 0 1 .85-.85h7.934a.85.85 0 1 1 0 1.7H4.533a.85.85 0 0 1-.85-.85Z"
              clipRule="evenodd"
            />
            <path
              fill="#081027"
              fillRule="evenodd"
              d="M0 2.267A2.27 2.27 0 0 1 2.267 0h7.582c.637 0 1.237.27 1.66.715l.007.008 4.887 5.329c.407.434.597 1.002.597 1.541v11.674a2.27 2.27 0 0 1-2.267 2.266H2.267A2.27 2.27 0 0 1 0 19.267v-17Zm9.848 0H2.267v17h12.466V7.584l-4.868-5.31H9.86a.046.046 0 0 0-.013-.007Z"
              clipRule="evenodd"
            />
            <path
              fill="#081027"
              fillRule="evenodd"
              d="M9.849 0a1.134 1.134 0 0 1 1.133 1.133V6.46h4.885a1.133 1.133 0 0 1 0 2.267H9.849a1.133 1.133 0 0 1-1.134-1.134v-6.46A1.133 1.133 0 0 1 9.85 0Z"
              clipRule="evenodd"
            />
          </svg>
        </div>
        <div className="text-xs text-white">Cv Havuzu</div>
      </AnimatedLink>
    </motion.div>
  );
}
