import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Footer from "../../mainComponents/footer";
import HeaderAll from "../../mainComponents/header-all";
import { getSinglePage } from '../../services/public';

export default function SinglePage() {
  const { slug } = useParams();
  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");

  const getText = async () => {
    const response = await getSinglePage(slug);
    const realcontent = JSON.parse(response.content);
    setTitle(response.title);
    setContent(realcontent);
  };

  useEffect(() => {
    getText();
  }, [slug]);

  const [pageReady, setReady] = useState(false);

  async function fetchData() {
    setReady(true);
  }
  
  useEffect(() => {
    fetchData();
  }, []);

  return (
    <React.Fragment>
      <HeaderAll />

      {/* Breadcrumb */}
      <div className="bg-primary-blue flex items-center h-12 w-full">
        <div className="pl-4 sm:pl-8 lg:pl-32 text-xs text-white font-semibold">
          Anasayfa - Teknopark - {title}
        </div>
      </div>

      {/* Banner Section */}
      <div className="bg-how-apply bg-no-repeat bg-cover w-full h-60 sm:h-72 flex justify-center items-center relative">
        <div className="w-full h-full absolute bg-primary-blue/50 inset-0 z-10">
          &nbsp;
        </div>
        <p className="text-white font-light text-lg sm:text-xl text-center w-[300px] sm:w-[450px] flex flex-col justify-center items-center absolute z-10">
          Trakya Teknopark
          <span className="font-bold text-2xl sm:text-3xl">{title}</span>
        </p>
      </div>

      {/* Content Section */}
      <div className="w-full h-full flex flex-col p-4 sm:p-10 md:p-20 gap-6 sm:gap-10 pages">
        <div dangerouslySetInnerHTML={{ __html: content }}></div>
      </div>

      <Footer />
    </React.Fragment>
  );
}
