import { postCompanies } from "../../../services/companies";
import { useNavigate } from "react-router-dom";
import { useState } from "react";

export default function NewCompany() {
  const [name, setName] = useState("");
 
  const navigate = useNavigate();

  async function handleSubmit() {
    const data = {
      name,
      imageId: 1,
    };
    const response = await postCompanies(data);
    navigate("/admin/companies");
  }
  return (
    <div className="flex flex-col items-center justify-center mt-12 space-y-4 ">
      <input
        type="text"
        className="input bg-white text-black font-poppins"
        onChange={(e) => setName(e.target.value)}
        placeholder="Firma Adı"
      />
 
      <button
        onClick={handleSubmit}
        className="bg-blue-600 w-56 h-10 rounded-lg text-white font-poppins"
      >
        Kaydet
      </button>
    </div>
  );
}
