import React from "react";
import Footer from "../../mainComponents/footer";

function index() {
  return (
    <React.Fragment>
      <div className="bg-primary-blue  flex items-center h-12 w-full">
        <div className="pl-32 text-xs text-white font-semibold">
          Anasayfa - Teknopark - Firmalarımız
        </div>
      </div>

      <div className="bg-how-apply w-full h-60 flex justify-center items-center relative">
        <div className="w-full h-full absolute bg-primary-blue/30 inset-0 z-10">
          &nbsp;
        </div>
        <p className="text-white font-light text-2xl w-[450px] flex flex-col justify-center items-center absolute z-10">
          Trakya Teknopark
          <span className="font-bold text-5xl flex">Destekler</span>
        </p>
      </div>

      <div className="w-full h-full flex flex-col p-20 gap-15 ">
        <h1 className="text-3xl font-light text-primary-blue">
          Vergi Muafiyetleri
        </h1>
        <p>
          <br></br>
          Kurumlar Vergisi Muafiyeti 26/06/2001 tarihli ve 4691 sayılı kanunla
          Teknoloji Geliştirme Bölgelerinin yasal zemini hazırlanmıştır.
          <br></br>
          <br></br> Bölgede yer alan gelir ve kurumlar vergisi mükelleflerinin,
          bölgedeki yazılım ve AR–GE' ye dayalı üretim faaliyetlerinden elde
          ettikleri kazançlar, faaliyete geçilmesinden itibaren 2023 yılına
          kadar kurumlar vergisinden muaf tutulacaktır.<br></br>
          <br></br>
          Bu süre, Bakanlar Kurulu tarafından seçilen ve hedef alınan belirli
          teknolojik alanlar ve ürünler için 10 yıla kadar uzatılabilir.
          <br></br>
          <br></br> Gelir Vergisi Muafiyeti <br></br> <br></br>26/06/2001
          tarihli ve 4691 sayılı kanunla Teknoloji Geliştirme Bölgelerinin yasal
          zemini hazırlanmıştır.<br></br> <br></br>
          Bölgede yer alan gelir vergisi mükelleflerinin, bölgedeki yazılım ve
          AR–GE' ye dayalı üretim faaliyetlerinden elde ettikleri kazançlar,
          faaliyete geçilmesinden itibaren 2023 yılına kadar gelir vergisinden
          muaf tutulacaktır.<br></br> <br></br> Bu süre, Bakanlar Kurulu
          tarafından seçilen ve hedef alınan belirli teknolojik alanlar ve
          ürünler için 10 yıla kadar uzatılabilir.<br></br> <br></br> KDV
          Muafiyeti <br></br> <br></br>Teknoloji geliştirme bölgesinde faaliyet
          gösteren 5035 sayılı yasanın ilgili maddelerinde belirtilen yazılım
          firmaları KDV vergisinden muaftır.<br></br> <br></br> Bölgede
          faaliyette bulunan girişimcilerin kazançlarının gelir ve kurumlar
          vergisinden istisna bulunduğu süre içinde münhasıran bu bölgelerde
          ürettikleri ve sistem yönetimi, veri yönetimi, iş uygulamaları,
          sektörel, internet, mobil ve askeri komuta kontrol uygulama yazılımı
          şeklindeki teslim ve hizmetleri katma değer vergisinden müstesnadır.
        </p>
        <br></br>

        <h1 className="text-3xl font-light text-primary-blue">
          Personel Avantajı
        </h1>
        <p>
          <br></br>
          Bölgede yer alan faaliyetlerde yürürlükteki iş ve çalışma mevzuatına
          göre personel istihdam edilir.<br></br> <br></br>Bu Bölgelerde 6224
          sayılı Yabancı Sermaye Kanunu ve ilgili mevzuat hükümleri çerçevesinde
          yabancı uyruklu yönetici ve vasıflı Ar-Ge personeli çalıştırılabilir.
          Kamu kurum ve kuruluşları ile üniversite personelinden Bölgede yer
          alan faaliyetlerde araştırmacı personel olarak hizmetine ihtiyaç
          duyulanlar, çalıştıkları kuruluşların izni ile sürekli veya yarı
          zamanlı olarak çalıştırılabilirler.
        </p>

        <h1 className="text-3xl font-light text-primary-blue">
          Üniversite İmkanları
        </h1>
        <br></br>
        <p>
          Profesyonel Teknopark yönetim anlayışı, geniş bir yelpazeye yayılan
          nitelikli teknopark destek hizmetleri.
          <br></br>
          <ul className="list-disc pl-8 flex flex-col gap-2">
            <li>
              Üniversitenin mevcut laboratuvar, donanım gibi altyapılarından
              yararlanma
            </li>
            <li>
              Eğitsel, sosyal, kültürel ve sportif olanaklarından yararlanma,
            </li>
            <li>
              Üniversitenin nitelikli insan kaynaklarından yararlanma olanağı.
            </li>
          </ul>
        </p>
        <br></br>

        <p>
          Öğretim Üyelerine Sağlanan Destekler<br></br> Kamu kurum ve
          kuruluşları ile üniversite personelinden Bölgede yer alan
          faaliyetlerde araştırmacı ve idari personel olarak hizmetine ihtiyaç
          duyulanlar, çalıştıkları kuruluşların izni ile sürekli veya yarı
          zamanlı olarak çalıştırılabilirler. Yarı zamanlı görev alan öğretim
          üyesi, öğretim görevlisi, araştırma görevlisi ve uzmanların bu
          hizmetleri karşılığı elde edecekleri gelirler, üniversite döner
          sermaye kapsamı dışında tutulur. Sürekli olarak istihdam edilecek
          personele kurumlarınca aylıksız izin verilir ve kadroları ile
          ilişkileri devam eder. Bunlardan 5434 sayılı Türkiye Cumhuriyeti
          Emekli Sandığı Kanununa tâbi personelin burada geçirdikleri süreler
          için emeklilik hakları 5434 sayılı Kanunun 31 inci maddesi hükümlerine
          uyulmak kaydıyla saklı kalır. 2547 sayılı Yükseköğretim Kanununun 36
          ncı maddesinin bu maddede yer alan düzenlemelere aykırı hükümleri
          uygulanmaz. Öğretim elemanları 2547 sayılı Kanunun 39 uncu maddesinde
          öngörülen yurt içinde ve yurt dışında geçici görevlendirme esaslarına
          göre yapacakları çalışmaları Üniversite Yönetim Kurulunun izni ile
          Bölgedeki kuruluşlarda yapabilirler. Aylıklı izinli olarak Bölgede
          görevlendirilen öğretim üyelerinin Bölgede elde edecekleri gelirler
          üniversite döner sermaye kapsamı dışında tutulur. Ayrıca, öğretim
          elemanları Üniversite Yönetim Kurulunun izni ile yaptıkları
          araştırmaların sonuçlarını ticarileştirmek amacı ile bu bölgelerde
          şirket kurabilir, kurulu bir şirkete ortak olabilir ve/veya bu
          şirketlerin yönetiminde görev alabilirler. Destek ve Teşvik Veren
          Kurumlar
        </p>

        <br></br>
        <p>
          Çeşitli kurumların destek ve teşviklerine aşağıdaki linklerden
          ulaşabilirsiniz.
        </p>
        <br></br>
        <ul className="list-disc pl-8 flex flex-col gap-2">
          <li>Avrupa Yatırım Bankası (EIB)</li>
          <li>Dış Ticaret Müsteşarlığı (DTM)</li>
          <li>İhracatı Geliştirme Etüd Merkezi (İGEME)</li>
          <li>İktisadi Kalkınma Vakfı (İKV)</li>
          <li>Türk Patent Enstitüsü (TPE)</li>
          <li>Türkiye Teknoloji Geliştirme Vakfı (TTGV)</li>
          <li>TÜBİTAK</li>
          <li>KOSGEB</li>
        </ul>
        <br></br>
        <h1 className="text-3xl font-light text-primary-blue">
          Teknoloji Geliştirme Bölgeleri Kanunu ve Yönetmeliği
        </h1>
        <br></br>
        <ul className="list-disc pl-8 flex flex-col gap-2">
          <li>
            6170 Sayılı Teknoloji Geliştirme Bölgeleri Kanunu'nda Değişiklik
            Yapılması Hakkında Kanun
          </li>
          <li>4691 sayılı Teknoloji Geliştirme Bölgeleri Kanunu</li>
          <li>Teknoloji Geliştirme Bölgeleri Uygulama Yönetmeliği</li>
          <li>Kurumlar Vergisi Sirküleri-1</li>
          <li>Kurumlar Vergisi Sirküleri-6</li>
          <li>T86 seri nolu Kurumlar Vergisi Genel Tebliği</li>
        </ul>
        <br></br>
        <h1 className="text-3xl font-light text-primary-blue">Arge Hakkında</h1>
        <br></br>
        <p>
          AR-GE, bilimsel ve teknik bilgi birikimini artırmak amacıyla,
          sistematik bir temele dayalı olarak yürütülen, yaratıcı çaba ve bu
          bilgi birikiminin yeni uygulamalarda kullanımıdır. Gelişmiş sanayi
          ülkelerinin yer aldığı OECD'ye göre AR-GE üç farklı uygulamayı
          bünyesinde barındırır; <br></br><br></br>Temel Araştırma (Basic Research): Belirli,
          özgün bir uygulama veya kullanım düşünülmeden, kuramsal veya deneysel
          çalışmalarla olguların ve gözlemlenebilir durumların altında yatana
          ilişkin yeni bilgi edinmeye denir. <br></br><br></br>Uygulamalı Araştırma (Applied
          Research): Uygulamalı araştırma da özgün bilgi üretmeye yöneliktir.
          Ana hedef olarak doğrudan özgün ve pratik bir amaç içerir.<br></br><br></br> Deneysel
          Geliştirme (Experimental Development): Araştırma ve/veya pratik
          deneyimden edinilmiş ve halen var olan bilginin üzerinde yükselen,
          ancak yeni materyaller, ürünler, devreler üretmeye; yeni süreçler,
          sistemler hizmetler oluşturmaya veya halen üretilmiş veya oluşturulmuş
          olanları büyük ölçüde iyileştirmeye yönelik sistemli çalışmalardır.
          OECD, AR-GE'nin diğer faaliyetlerden ayırt edilmesinde şu tanımı
          getirmektedir: AR-GE faaliyetleri bilimsel veya teknolojik
          belirsizliğin olduğu durumlarla ilgili faaliyetlerdir.<br></br><br></br> Her AR-GE
          çalışması mutlaka şu üç aşamayı içermelidir: Ölçme, izleme ve
          değerlendirme. Bunu başarabilmek sistematik çalışmayla mümkündür. Bu
          nedenle proje bazlı çalışma gereklidir. Her çalışma bir proje olarak
          ele alınmalı, gerekirse alt projelere bölünerek yetkili kişilerce
          yönetilmelidir. Her projenin mutlak suretle bir müşterisi olmalıdır.<br></br><br></br>
          AR-GE Kapsamında Bulunan Faaliyetler Nelerdir? <br></br><br></br>Bilimsel ve teknolojik
          alanlardaki belirsizlikleri gidermek ve bunları aydınlatmak amacıyla,
          bilim ve teknolojinin gelişmesini sağlayacak yeni teknik bilgilerin
          elde edilmesi.<br></br><br></br> Yeni üretim yöntem ve süreçlerinin bulunması veya
          geliştirilmesi. <br></br><br></br>Yeni ürünler, madde ve malzemeler, araçlar, gereçler,
          işlemler, sistemler geliştirilmesine yönelik olarak yeni yöntemler
          geliştirilmesi veya yeni teknikler üretilmesi.<br></br><br></br> Bir ürünün maliyetini
          düşürücü, kalite, standart ve performansını yükseltici yeni
          tekniklerin / teknolojilerin bulunması.<br></br><br></br> Yeni ve özgün tasarıma dayanan
          yazılım faaliyetleri. Bir faaliyetin AR-GE faaliyeti olarak
          nitelendirilebilmesi için, yukarıda yer alan amaçlardan en az birine
          yönelik olması gerekmektedir.<br></br><br></br>
        </p>
        <br></br>
        <h1 className="text-3xl font-light text-primary-blue">AR-GE Kapsamı Dışında Kalan Faaliyetler Nelerdir ?</h1>
        <br></br>
        <ul className="list-disc pl-8 flex flex-col gap-2">
          <li>
          Pazar araştırması veya satış promosyonu.
          </li>
          <li>Kalite kontrol.</li>
          <li>Petrol, doğalgaz, maden rezervleri arama ve sondaj faaliyetleri.</li>
          <li>İcat edilmiş ya da mevcut geliştirilmiş süreçlerin kullanımı.</li>
          <li>Biçimsel değişiklikler (şekil, renk, dekorasyon vb. estetik ve görsel değişiklikler).</li>
          <li>Bilimsel ve teknolojik yenilik doğurmayan rutin faaliyetler (rutin veri toplama, rutin analiz için kullanılan program, yazılım vs.).</li>
          <li>Üretilen prototiplerin rutin ayarlamaları.</li>
          <li>İlk kuruluş aşamasında kuruluş ve örgütlenmeyle ilgili araştırma giderleri.</li>
          <li>Proje sonucunda geliştirilen fikri mülkiyet haklarının korunmasına yönelik çalışmalar.</li>
          <li>Numune verilmek amacıyla prototiplerden kopyalar çıkarıp test amaçlı dağıtılması ve tüketici testleri.</li>
        </ul>
        <br></br>
        <h1 className="text-3xl font-light text-primary-blue">Teknoparklar Hakkında</h1>
        <br></br>
        <ul className="list-disc pl-8 flex flex-col gap-2">
            <li>AR-GE ağırlıklı faaliyetlerle bölgesel ve yerel ekonominin yeniden yapılanmasını sağlamak.</li>
            <li>Yörenin ekonomik faaliyetlerini çeşitlendirmek ve yerel sanayinin modernizasyonunu teşvik etmek.</li>
            <li>Bölgeye yabancı sermaye çekmek.</li>
            <li>Nitelikli kişilere iş ve girişimcilik imkanları yaratarak beyin göçünü önlemek</li>
            <li>Üniversite binalarına ve tesislerine fiziksel yakınlık yoluyla üniversite kampüslerine benzer, kompakt ve düzenli planlanmış yaşam yerleşkelerinin oluşmasına öncülük etmek</li>
            <li>Bulundukları çevre için modernizasyon ve gayrimenkul değerlendirici etki yaratmak.</li>
            <li>Yarattıkları kaliteli istihdam sayesinde bulundukları çevrenin refah düzeyini arttırmak vb.</li>
            <li>Üniversite-sanayi ilişkilerinin somut işbirliğine dönüşmesini sağlamak.</li>
            <li>AR-GE ve yazılıma dayalı katma değeri yüksek ürün ve hizmetlerin üretiminde artış sağlamak ve bu tarz ürünlerde dışa bağımlılığı azaltmak</li>
            <li>Yüksek teknoloji kökenli firmaların oluşmasını ve gelişmesini teşvik etmek</li>
            <li>Üniversitelerdeki akademik bilginin teknolojik ürünlere dönüştürülüp ticaretleştirilmesi ve teknoloji transferi için uygun ortam yaratmak.</li>
            <li>Firmalar arası sinerji ve işbirliği fırsatlarını arttırmak.</li>
            <li>Akademik personel ve öğrenciler için iş ve danışmanlık fırsatları yaratmak</li>
            <li>Trakya Üniversitesi'nin bilgi birikimini ve araştırma altyapısını Teknopark firmalarının kullanımına sunarak, akademik bilginin sanayiye aktarılmasına aracılık yapar,</li>
            <li>Sanayideki gelişmeleri Üniversite ortamına taşıdığı için yeni işbirliklerine olanak tanır,</li>
            <li>Akademisyenler tarafından şirket kurulmasını veya akademisyenlerin şirket ortağı olmalarını destekler,</li>
            <li>'Ön Kuluçka' ve 'Kuluçka' hizmetleri sunarak, yeni kurulan şirketlere başlattıkları Ar-Ge Projelerinin uzun ve emek-yoğun dönemlerinde destek olur,</li>
            <li>İhtiyaca yönelik eğitim/seminerlerle veya danışmanlık hizmetleri ile şirketlerin gelişimlerine katkıda bulunur,</li>
            <li>Bir Ar-Ge projesinin ihtiyacı olan tüm altyapı ve üstyapı imkânlarını sunar,</li>
            <li>Bölgesinde istidam edilen tüm personele keyifli ve verimli bir çalışma atmosferi sunmak amacıyla sosyal olanaklar sağlar,</li>
            <li>Teknoloji Geliştirme Bölgeleri Yasasındaki muafiyetlerin şirketler tarafından kullanılmasını sağlar; yetkili kamu mercilerine süreci raporlar,</li>
            <li>Akademisyenlerin, öğrencilerin ve nitelikli Ar-Ge personelinin istihdam edilmelerine olanak tanır, onların gelişimine katkıda bulunur,</li>
            <li>Tüm paydaşlar arasında ve uluslararası alanda sinerjinin oluşumuna önayak olur,</li>
            <li>Teknoloji Geliştirme Bölgeleri, Ar-Ge, İnovasyon ve Girişimcilik konularında toplumsal farkındalık yaratır.</li>
            
        
        </ul>


      </div>

      <Footer />
    </React.Fragment>
  );
}

export default index;
