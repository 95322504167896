import React from "react";
import Footer from '../../mainComponents/footer';
import HeaderAll from '../../mainComponents/header-all/index';

function Index() {
  return (
    <React.Fragment>

      <HeaderAll />
      

      {/* Breadcrumb */}
      <div className="bg-primary-blue flex items-center h-12 w-full">
        <div className="pl-4 sm:pl-8 lg:pl-32 text-xs text-white font-semibold">
          Anasayfa - Teknopark - Firmalarımız
        </div>
      </div>

      {/* Banner Section */}
      <div className="bg-educationApply w-full h-60 flex justify-center items-center relative">
        <div className="w-full h-full absolute bg-primary-blue/30 inset-0 z-10">&nbsp;</div>
        <p className="text-white font-light text-2xl text-center flex flex-col justify-center items-center absolute z-10">
          Trakya Teknopark
          <span className="font-bold text-4xl">Eğitim Başvuru</span>
        </p>
      </div>

      {/* Notice Text */}
      <p className="text-primary-blue font-light text-sm sm:text-base text-center mt-6 sm:mt-10 mx-4">
        Covid-19 salgını sebebiyle eğitimlerimize online olarak devam etmekteyiz. Aşağıdaki formu eksiksiz doldurarak talepte bulunabilirsiniz.
      </p>

      {/* Form Section */}
      <div className="flex flex-col md:flex-row justify-center mx-auto max-w-7xl border-gray-300 border-y-2 py-5 px-4 sm:px-8 md:px-20 mt-6 sm:mt-10">
        <div className="flex flex-col gap-6 sm:gap-8 w-full md:w-3/5">
          <input
            type="text"
            placeholder="Ad Soyad"
            className="input w-full bg-white border-primary-blue border-2 rounded-full px-3 py-3"
          />
          <input
            type="text"
            placeholder="Cinsiyet"
            className="input w-full bg-white border-primary-blue border-2 rounded-full px-3 py-3"
          />
          <input
            type="text"
            placeholder="İstenilen Eğitim"
            className="input w-full bg-white border-primary-blue border-2 rounded-full px-3 py-3"
          />
        </div>

        <div className="flex flex-col gap-6 sm:gap-8 w-full md:w-2/5 mt-6 md:mt-0">
          <input
            type="text"
            placeholder="E-Posta"
            className="input w-full bg-white border-primary-blue border-2 rounded-full px-3 py-3"
          />
          <input
            type="text"
            placeholder="Telefon Numarası"
            className="input w-full bg-white border-primary-blue border-2 rounded-full px-3 py-3"
          />
        </div>
      </div>

      {/* Submit Button */}
      <div className="flex justify-center md:justify-end mt-10 sm:mt-20 px-4 sm:px-8 md:pr-20">
        <button className="flex justify-center items-center bg-primary-blue text-white rounded-full w-full sm:w-64 py-3">
          Gönder
        </button>
      </div>

      <Footer className="mt-10" />
    </React.Fragment>
  );
}

export default Index;
