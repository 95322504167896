import React from "react";

const Slide = ({ index, slidesRefs, imageUrl, typeId, title }) => {
  const typeColor = {
    0: {
      color: "bg-primary-red",
      name: "Haber",
    },
    1: {
      color: "bg-primary-yellow",
      name: "Duyuru",
    },
    2: {
      color: "bg-primary-blue-lighter",
      name: "Etkinlik",
    },
  };

  return (
    <div
      ref={(el) => {
        if (!el) return;
        slidesRefs.current[index] = el;
      }}
      key={index}
      className="relative h-96 min-w-full lg:min-w-[50%] xl:min-w-[28%]"
    >
      <div
        className={`
          absolute right-0 top-0 z-10 flex h-full w-6 items-center justify-center
          bg-yellow-500
        `}
      >
        <span className="-mb-44 -rotate-90 text-xs font-semibold text-white">
          {/* {title} */}
HABER        </span>
      </div>
      <img
        src={"https://be.trakyateknopark.com.tr/uploads/" + imageUrl}
        className="h-full w-full object-cover"
        alt=""
      />
    </div>
  );
};

export default Slide;
