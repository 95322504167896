import React from "react";
import HeaderAll from "../../mainComponents/header-all";
import Footer from "../../mainComponents/footer";

function TekonparkGameHub() {
  return (
    <React.Fragment>
      <HeaderAll />
      <div className="bg-primary-blue flex items-center h-12 w-full">
        <div className="pl-4 sm:pl-8 md:pl-16 lg:pl-32 text-xs text-white font-semibold">
          {" "}
          Anasayfa - Teknopark - Teknopark GameHub
        </div>
      </div>
      <div className="bg-how-apply bg-no-repeat bg-cover w-full h-60 sm:h-72 flex justify-center items-center relative">
        <div className="w-full h-full absolute bg-primary-blue/50 inset-0 z-10">
          &nbsp;
        </div>
        <p className="text-white font-light text-lg sm:text-xl text-center w-[300px] sm:w-[450px] flex flex-col justify-center items-center absolute z-10">
          Trakya Teknopark
          <span className="font-bold text-2xl sm:text-3xl">
            {" "}
            Teknopark GameHub
          </span>
        </p>
      </div>

      <div className="container mx-auto  py-11">
        <div className="flex flex-col gap-8">
          <h1 className=" text-2xl font-light text-primary-blue">Hakkımızda</h1>
          <div className="text-justify">
            Game Dev Hub, oyun geliştiricilerin ve oyunla ilgili herhangi bir
            etkinlik üzerinde çalışan herkesin oyun oluşturmak ve geliştirmek
            için Trakya Teknopark ‘ta bir araya geldiği ortak çalışma alanı
            sağlayan kâr amacı gütmeyen bir kuruluştur. Vizyonumuz, oyunların
            nasıl oluşturulacağı ve geliştirileceği hakkında rehberlik etmek,
            destek sağlamak ve oyun oluşturma ve geliştirme fırsatları
            sağlamaktır. Hub, oyun geliştiricileri, grafik tasarımcılar,
            müzisyenler, yazarlar ve benzeri gibi oyunla ilgili herhangi bir
            etkinlik üzerinde çalışan herkes içindir. Ortak çalışma alanı, ağ
            oluşturma, sosyalleşme ve rahatlama için ortak alan, gruplanma
            fırsatı ve mentorluk desteği sağlayarak öğrencileri ve oyunla ilgili
            herhangi bir etkinlik üzerinde çalışan herkesi destekliyoruz.
            Bizimle herhangi bir şey hakkında konuşmak isterseniz, bizimle
            iletişime geçin: cansu@trakyateknopark.com.tr
          </div>
        </div>
        <div className="mt-8 flex flex-col gap-8">
          <h1 className=" text-2xl font-light text-primary-blue">Fırsatlar</h1>
          <ul className="list-disc pl-8">
            <li>
              Ortak Çalışma Alanı: Ofis ortamı, ortak alanlar, yüksek hızlı
              internet bağlantısı ve daha fazlasını içeren ortak çalışma alanı.
            </li>
            <li>
              Gruplanma fırsatı: Oyunlar bir ekiple yapılır. Eğer bir grubunuz
              yoksa veya bir ekibe ihtiyacınız varsa, hayalinizdeki oyunu yapmak
              için kolayca gruplanabilirsiniz.
            </li>
            <li>
              Ağ oluşturma: Trakya Teknopark ve iş liderleri ağımıza erişin.
            </li>
            <li>
              Ortak Alanlar: Ortak alanlar, diğer insanlarla sosyalleşmek ve
              dinlenmek içindir. Burada bir mola vermek ve dinlenmek için
              rahatlatıcı bir ortam var.
            </li>
            <li>
              Mentorluk: Game Dev Hub, oyunlar oluşturmak ve geliştirmek için
              mentorluk verir. Mentorlar, oyunun yaratılması ve geliştirilmesi
              ile ilgilenen kişilerdir. Mentorlar yardıma ihtiyacınız olduğunda
              ya da bir sorunla karşılaştığınızda yanınızda olacak.
            </li>
          </ul>
        </div>
        <div className="flex flex-col gap-8 mt-8">
          <h1 className=" text-2xl font-light text-primary-blue">
            Etkinlikler
          </h1>
          <div className="text-justify">
            Game Jam’ler: Game Jam’ler, oyun geliştiricilerin sınırlı bir süre
            içinde bir oyun yaratmaya çalıştıkları etkinliklerdir. Art Jam’ler:
            Art Jam’ler, grafik tasarımcılarının sınırlı bir süre içinde bir
            oyun fikrine göre konsept art yaratmaya çalıştığı etkinliklerdir.
            Game Idea Jam’ler: Game Idea Jam’ler, insanların sınırlı bir süre
            içinde bir oyun fikri veya hikayesi yaratması gereken zorluklardır.
            Uzman konuşmaları: Uzman konuşmaları bilgilendirici konuşmalardır.
            Bir oyun oluşturma ve geliştirme konusunda birçok alan vardır ve bu
            uzman konuşmaları, video oyunları oluşturma konusunda yeni olanlara
            ve video oyunları ve diğer alanlar hakkında kendilerini geliştirmeye
            ihtiyaç duyanlara yardımcı olacaktır. Ağ Toplantıları: Ağ
            Toplantıları, oyunla ilgili herhangi bir faaliyette çalışan herkesin
            mentorlar ve Trakya Teknopark yetkilileri ile buluştuğu bir ağ
            toplantısı etkinliğidir. Ofiste çalışmak, oyun geliştirmek için bir
            grup bulmak ve çok daha fazlası için fırsatlar barındırır.
          </div>
        </div>
      </div>

      <Footer />
    </React.Fragment>
  );
}

export default TekonparkGameHub;
