import slugify from 'react-slugify';
import {Link} from "react-router-dom";
import React from "react";

const SlideDescription = ({
  index,
  slidesDescRefs,
  title,
  description,
  id,
  typeId,
  slug
}) => {
  const typeColor = {
    0: {
      color: "bg-primary-red",
      name: "Haber",
    },
    1: {
      color: "bg-primary-yellow",
      name: "Duyuru",
    },
    2: {
      color: "bg-primary-blue-lighter",
      name: "Etkinlik",
    },
  };
  const trimmedDescription = JSON.parse(description)
    .split(' ')
    .slice(0, 10)
    .join(' ') + '...';
  return (
    <div
      ref={(el) => {
        if (!el) return;
        slidesDescRefs.current[index] = el;
      }}
      key={index}
      className="min-w-full text-primary-blue lg:min-w-[50%] xl:min-w-[28%]"
    >
      <h1 className="mb-2 min-h-16 font-medium">{title}</h1>
      <p className="text-justify text-sm"> 
      <div dangerouslySetInnerHTML={{ __html: trimmedDescription }}></div>
</p>
      <div className="mt-4 flex justify-end">
        <Link
          to={`/haberler/${slugify(slug)}`}
          className="text-base font-light text-primary-blue"
        >
          Devamı için
          <span className={`${typeColor[typeId]?.color})`}> tıklayın.</span>
        </Link>
      </div>
    </div>
  );
};

export default SlideDescription;
