import { MdMailOutline } from "react-icons/md";
import { CiLock } from "react-icons/ci";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { IoEye, IoEyeOff } from "react-icons/io5";
import { postAuthLogin } from "../../services/auth";
import { setToken, deleteToken, getToken } from "../../libs/token";

function LoginPage() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [visible, setVisible] = useState(false);
  const navigate = useNavigate();

  const handleLogin = async () => {
    const token = getToken("teknotoken");
    if (token) {
      const deltoken = deleteToken("teknotoken");
    }

    const response = await postAuthLogin({ email, password });
    if (response) {
      setToken("teknotoken", response);
      navigate("/admin/pages/0");
    } else {
      alert("Login failed");
    }
  };
  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100">
      <div className="mb-8">
        <img
          src="/logo-dark.svg"
          alt="Trakya Teknopark Logo"
          className="h-12"
        />
      </div>
      <div className="w-full max-w-xs">
        <form className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
          <h2 className="text-xl font-semibold mb-6  text-center">Giriş Yap</h2>
          <label className="input gap-1 mb-5 input-bordered bg-white flex items-center ">
            <MdMailOutline />
            <input
              className=" grow text-sm  w-full py-2 px-3 text-gray-700 leading-tight"
              id="email"
              type="email"
              onChange={(e) => setEmail(e.target.value)}
              value={email}
              placeholder="Email"
            />{" "}
          </label>
          <label className="input mb-10 input-bordered bg-white flex items-center gap-2">
            <CiLock />
            <input
              className=" grow text-sm  w-full py-2 px-3 text-gray-700 leading-tight"
              id="password"
              type="password"
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />{" "}
          </label>

          <div className="flex items-center justify-between">
            <button
              className="bg-blue-900 hover:text-gray-300 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline w-full"
              type="button"
              onClick={handleLogin}
            >
              Giriş Yap
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default LoginPage;
