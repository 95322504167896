import axiosClient from "../libs/axios";

export const PostPage = async (data) => {
  const response = await axiosClient.post("/pages/create", data);
  return response.data;
};

 export const UpdatePage = async (data,id) => {
    const response = await axiosClient.put("/pages/update/" + id, data); 
    return response.data;
};

export const DeletePage = async (id) => {
    const response = await axiosClient.delete("/pages/delete/" + id);
    return response.data;
  };

export const GetSinglePage = async (id) => {
  const response = await axiosClient.get("/pages/" + id);
  return response.data;
};

