import React from "react";
import { Routes, Route } from "react-router-dom";
import Home from "../pages/home";
import Companies from "../pages/companies";
import Howtoapply from "../pages/howtoapply/index";
import Supports from "../pages/supports/index";
import ApplyToEducation from "../pages/applyToEducation/index";
import News from "../pages/news/index";
import SinglePage from "../pages/[id]";
import InsideNews from "../pages/news/id/index";
import LoginPage from "../pages/login/login.js";
import AdminLayout from "../layouts/adminLayout.js";
import Admin from "../pages/admin/index.js";
import AdminCompanies from "../pages/admin/companies/allCompanies.js";
import NewCompany from "../pages/admin/companies/newCompany.js";
import EditCompany from "../pages/admin/companies/editCompany.js";
import Contact from "../pages/contact/index.js";
import AdminPages from "../pages/admin/pages/allPages.js";
import AdminEditPage from "../pages/admin/pages/editPage.js";
import AdminNewPage from "../pages/admin/pages/newPage.js";
import Blog from "../pages/blog/index.js";
import InsideBlogs from "../pages/blog/id/index.js";
import BoardOfManagement from "../pages/boardOfManagement/index.js";
import TeknoAcademy from '../pages/teknoparkAcademy/teknoparrkAcademy.js'
import TeknoparkChild from '../pages/teknoparkChild/teknoparkChild.js'
import ManagementTeam from "../pages/managementTeam/index.js";
import TeknoIncubation from '../pages/teknoIncubation/teknoIncubation.js'
import TekonparkGameHub from "../pages/teknoGameHub/index.js";

function AppRouter() {
  return (
    <div>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/tekno-gamehub" element={<TekonparkGameHub />} />
        <Route path="/yonetim-kurulu" element={<BoardOfManagement />} />
        <Route path="/yonetim-kadrosu" element={<ManagementTeam />} />
        <Route path="/teknopark-blog"  >
          <Route path="" element={<Blog />} />
          <Route path=":id" element={<InsideBlogs />} />
        </Route>
        <Route path="/haberler">
          <Route path="" element={<News />} />
          <Route path=":id" element={<InsideNews />} />
        </Route>
        <Route path=":slug" element={<SinglePage />} />
        <Route path="/firmalarimiz" element={<Companies />} />
        <Route path="/iletisim" element={<Contact />} />
        <Route path="/nasil-basvurulur" element={<Howtoapply />} />
        <Route path="/supports" element={<Supports />} />
        <Route path="/egitim-basvuru" element={<ApplyToEducation />} />
        <Route path="/admin" element={<AdminLayout />}>
          <Route path="companies">
            <Route path="" element={<AdminCompanies />} />
            <Route path="new" element={<NewCompany />} />
            <Route path=":id" element={<EditCompany />} />
          </Route>
          <Route path="pages">
            <Route path="" element={<AdminPages />} />
            <Route path=":id" element={<AdminPages />} />
            <Route path=":id/edit/:realid" element={<AdminEditPage />} />
            <Route path="new" element={<AdminNewPage />} />
          </Route>
        </Route>
        <Route path="/login" element={<LoginPage />} />
        <Route path="/teknopark-akademi" element={<TeknoAcademy/>}/>
        <Route path="/teknopark-cocuk" element={<TeknoparkChild/>}/>
        <Route path="/teknopark-kulucka" element={<TeknoIncubation/>}/>
        
      </Routes>
    </div>
  );
}

export default AppRouter;
