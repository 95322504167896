import { deleteToken, getToken } from './token'
import axios from 'axios'

// Client Componentlerde kullanılırsa serverdamıyız diye kontrol etmemiz gerekir.
// şimdilik serverda kullanılacak

const apiUrl =    "https://be.trakyateknopark.com.tr/api"

const axiosClient = axios.create({
  baseURL: apiUrl,
  headers: {
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*'
  }
})

axiosClient.interceptors.request.use(async (config) => {
  let token = null

  token = await getToken('teknotoken')

  if (token) {
    config.headers['Authorization'] = `Bearer ${token}`
  }

  return config
})

axiosClient.interceptors.response.use(
  (response) => {
    return response
  },
  async (error) => {
    if (error?.response?.status === 401) {
      deleteToken('teknotoken')
      deleteToken('user')
    }
    if (error?.response?.status === 404) {
      return Promise.reject(error)
    }
    return Promise.reject(error)
  }
)

export default axiosClient
