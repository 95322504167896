import React, { useEffect, useRef, useState } from "react";
import SlideDescription from "./slideDescription";
import Slide from "./slide";
import { getBlogs } from "../../../../services/blog";
import { getAllPages } from "../../../../services/public";

export default function News() {
  const [newsData, setNewsData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [currentSlideIndex, setCurrentSlideIndex] = useState(0);

  const [news, setNews] = useState([]);
  async function fetchData() {
    const get = await getAllPages(1);

    setNews(get);
  }
  useEffect(() => {
    fetchData();
  }, []);

  const imagesUrl = [
    "/temp/assets/1.jpg",
    "/temp/assets/2.jpg",
    "/temp/assets/3.jpg",
    "/temp/assets/4.jpg",
    "/temp/assets/5.jpg",
    "/temp/assets/6.jpg",
  ];


  const SLIDES2 = newsData.map((item, index) => ({
    id: item.id,
    image_url: item.image_url || imagesUrl[index % 6],
    title: item.title,
    description: item.content,
    type_id: item.type_id,
  }));

  const slidesRefs = useRef([]);
  const slidesDescRefs = useRef([]);
  const descContainerRef = useRef(null);
  const slidesContainerRef = useRef(null);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentSlideIndex((prev) => (prev + 1 < news.length ? prev + 1 : 0));
    }, 3000);
    return () => clearInterval(interval);
  }, [news.length]);

  useEffect(() => {
    slidesContainerRef.current?.scrollTo({
      left: slidesRefs.current[currentSlideIndex]?.offsetLeft,
      behavior: "smooth",
    });

    descContainerRef.current?.scrollTo({
      left: slidesDescRefs.current[currentSlideIndex]?.offsetLeft,
      behavior: "smooth",
    });
  }, [currentSlideIndex]);

  const handlePrevSlide = () => {
    setCurrentSlideIndex((prev) => (prev - 1 < 0 ? news.length - 1 : prev - 1));
  };

  const handleNextSlide = () => {
    setCurrentSlideIndex((prev) => (prev + 1 >= news.length ? 0 : prev + 1));
  };

  return (
    <section className="my-10 mr-0 w-full overflow-hidden lg:my-28">
      <div className="content">
        <div className="heading flex flex-col items-center justify-center gap-5">
          <h1 className="text-center text-3xl font-semibold text-primary-blue-light">
            Trakya Teknopark&apos;ta neler oluyor?
          </h1>
          <div className="max-w-4xl text-center text-base lg:text-xl">
            Trakya Teknopark, yenilikçi projeler, girişimler ve teknoloji
            dünyasının nabzını tutuyor. Burada, en son haberler, duyurular ve
            etkinliklerle ilgili güncel bilgilere kolayca ulaşabilirsiniz.
            Teknolojiye dair heyecan verici gelişmeleri takip edin ve iş
            dünyasındaki fırsatları kaçırmayın. Trakya Teknopark&apos;ın dinamik
            dünyasını keşfedin!
          </div>
        </div>
        <div className="slider relative overflow-hidden">
          <div
            className="absolute left-[-5%] right-[-5%] top-0 z-20 h-20 w-[110%] bg-[#FEFEFE]"
            style={{
              clipPath: "ellipse(50% 90% at top center)",
            }}
          />

          <div className="relative">
            <button
              onClick={handlePrevSlide}
              className="absolute left-6 top-[50%] z-30"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width={40}
                height={40}
                fill="none"
              >
                <path
                  fill="#fff"
                  d="M0 20a20 20 0 1 0 40 0 20 20 0 0 0-40 0Zm19.462-10a1.787 1.787 0 0 1 0 2.5l-5.712 5.75h17.675a1.75 1.75 0 0 1 0 3.5h-17.5l5.537 5.538a1.768 1.768 0 0 1-2.5 2.5L7.125 19.9l9.863-9.9a1.75 1.75 0 0 1 2.474 0Z"
                />
              </svg>
            </button>
            <button
              onClick={handleNextSlide}
              className="absolute right-0 top-[50%] z-30"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width={40}
                height={40}
                fill="none"
              >
                <path
                  fill="#fff"
                  d="M40 20a20 20 0 1 0-40 0 20 20 0 0 0 40 0ZM20.538 30a1.787 1.787 0 0 1 0-2.5l5.712-5.75H8.575a1.75 1.75 0 0 1 0-3.5h17.5l-5.537-5.537a1.768 1.768 0 0 1 2.5-2.5l9.837 9.887-9.863 9.9a1.75 1.75 0 0 1-2.474 0Z"
                />
              </svg>
            </button>
            <div
              className="flex flex-row gap-14 overflow-x-hidden"
              ref={slidesContainerRef}
            >
              {isLoading ? (
                <div className="flex animate-pulse self-center text-xl">
                  Haberler Yükleniyor ...
                </div>
              ) : (
                news.map((slide, index) => (
                  <Slide
                    key={slide.id}
                    index={index}
                    slidesRefs={slidesRefs}
                    imageUrl={slide.fileName}
                    typeId={slide.type_id - 1}
                    title={slide.title}
                  />
                ))
              )}
            </div>
          </div>
          <div
            className="absolute bottom-0 left-[-5%] right-[-5%] z-20 h-20 w-[110%] bg-[#FEFEFE]"
            style={{
              clipPath: "ellipse(50% 90% at bottom center)",
            }}
          />
        </div>
        <div className="slider relative overflow-hidden">
          <div className="relative">
            <div
              className="flex flex-row gap-14 overflow-x-hidden"
              ref={descContainerRef}
            >
              {isLoading ? (
                <div className="flex animate-pulse self-center text-xl">
                  Haberler Yükleniyor ...
                </div>
              ) : (
                news.map((slide, index) => (
                  <SlideDescription
                    key={slide.id}
                    index={index}
                    title={slide.title}
                    id={slide.id}
                    description={slide.content}
                    slidesDescRefs={slidesDescRefs}
                    typeId={slide.type_id - 1}
                    slug={slide.slug}
                  />
                ))
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
