import React from "react";
import HeaderAll from "../../mainComponents/header-all";
import Footer from "../../mainComponents/footer";

function teknoparrkAcademy() {
  return (
    <React.Fragment>
      <HeaderAll />
      <div className="bg-primary-blue flex items-center h-12 w-full">
        <div className="pl-4 sm:pl-8 md:pl-16 lg:pl-32 text-xs text-white font-semibold">
          {" "}
          Anasayfa - Teknopark - Teknopark Akademi
        </div>
      </div>

      <div className="bg-how-apply bg-no-repeat bg-cover w-full h-60 sm:h-72 flex justify-center items-center relative">
        <div className="w-full h-full absolute bg-primary-blue/50 inset-0 z-10">
          &nbsp;
        </div>
        <p className="text-white font-light text-lg sm:text-xl text-center w-[300px] sm:w-[450px] flex flex-col justify-center items-center absolute z-10">
          Trakya Teknopark
          <span className="font-bold text-2xl sm:text-3xl">
            {" "}
            Teknopark Akademi
          </span>
        </p>
      </div>

      <div className="container mx-auto  py-11">
        <div className="flex flex-col gap-8">
          <h1 className=" text-2xl font-light text-primary-blue">
            Teknopark Akademi
          </h1>
          <div className="text-justify">
            Teknopark Akademi, Ar-Ge ve inovasyon odaklı yüksek katma değer
            çıktıları, ürün ya da hizmet olarak ortaya koyan veya koymak isteyen
            girişimci adaylarına, yatırımcılara ve öğrencilere tüm süreçlerde
            ihtiyaç duyacakları bilgilerle ilgili eğitim verme misyonunu
            benimsemiştir. Teknopark akademi aynı zamanda iş fikri olan ya da iş
            fikri oluşturmak isteyenlerin bilgi çemberinde sinerji yaratmasını
            da amaçladığından aynı uzmanlıklara sahip kişileri bir araya gelerek
            network oluşturma imkanını da bir arada sunmaktadır.
          </div>
        </div>
        <div className="mt-8 flex flex-col gap-8">
          <h1 className=" text-2xl font-light text-primary-blue">Fırsatlar</h1>
          <ul className="list-disc pl-8">
            <li> İleri Teknoloji Eğitimleri</li>
            <li>Girişimcilik Eğitimleri</li>
            <li>Nesnelerin İnterneti</li>
            <li>Finans Teknolojileri</li>
            <li>Yazılım Araçları</li>
            <li>Big Data</li>
          </ul>
        </div>
      </div>

      <Footer />
    </React.Fragment>
  );
}

export default teknoparrkAcademy;
