import React, { useEffect, useParams, useState } from "react";
import Footer from "../../mainComponents/footer";
import Apply from "../../companiesImages/nasil-basvurulur-bg.png";
import { Link, Outlet } from "react-router-dom";
import { getAllPages } from "../../services/public";
import HeaderAll from "../../mainComponents/header-all";
function Blog() {
  const [blogs, setBlogs] = useState([]);

  async function fetchData() {
    const get = await getAllPages(2);
    setBlogs(get);
  }

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <React.Fragment>
      <HeaderAll />
      <div className="bg-primary-blue flex items-center h-12 w-full">
        <div className="pl-4 sm:pl-8 lg:pl-32 text-xs text-white font-semibold">
          Anasayfa - Teknopark - Blog
        </div>
      </div>

      <div className="bg-blogPage w-full h-60 sm:h-72 flex justify-center items-center relative">
        <div className="w-full h-full absolute bg-primary-blue/30 inset-0 z-10">
          &nbsp;
        </div>
        <p className="text-white font-light text-lg sm:text-2xl w-[300px] sm:w-[450px] flex flex-col justify-center items-center absolute z-10 text-center">
          Trakya Teknopark
          <span className="font-bold text-2xl sm:text-4xl">Blog</span>
        </p>
      </div>

      <div className="flex flex-col gap-6 sm:gap-10 px-4 sm:px-10 py-4">
        {blogs.map((blogs, index) => (
          <div
            key={index}
            className="flex flex-col lg:flex-row gap-4 lg:gap-8 mt-6 sm:mt-10 mx-2 sm:mx-5 relative"
          >
            <div className="flex">
              <div className="bg-yellow-500 text-white w-8 sm:w-10 flex flex-col justify-end">
                <p className="[writing-mode:vertical-rl] flex items-center justify-center [transform:rotate(180deg)] text-sm sm:text-lg">
                  <span className="mt-2 sm:mt-5">Blog</span>
                </p>
              </div>
              <img
                src={
                  "https://be.trakyateknopark.com.tr/uploads/" + blogs.fileName
                }
                className="w-[200px] sm:w-[350px] h-[200px] sm:h-[300px] object-cover"
              />
            </div>

            <div className="flex flex-col gap-4 sm:gap-6">
              <h1 className="text-xl sm:text-2xl text-primary-blue">
                {blogs.title}
              </h1>
              <p className="text-primary-blue">
                <div
                  dangerouslySetInnerHTML={{
                    __html:
                      JSON.parse(blogs.content)
                        .split(" ")
                        .slice(0, 10)
                        .join(" ") + "...",
                  }}
                ></div>
              </p>
              <Link
                to={blogs.slug}
                className="cursor-pointer w-max flex gap-2 text-primary-blue self-end"
              >
                Devamı için{" "}
                <span className="bg-yellow-400 flex justify-center items-center px-2 py-1">
                  tıklayın.
                </span>
              </Link>
            </div>
          </div>
        ))}

        {/* 
        <div className="max-w-full flex gap-28 justify-center mt-10 mx-auto relative">
          <div className="flex">
            <div className="bg-red-500 text-white w-10 flex flex-col justify-end">
              <p className="[writing-mode:vertical-rl]  flex items-center justify-center [transform:rotate(180deg)]">
                <span className="mt-5">Duyuru</span>
              </p>
            </div>
            <img src={Apply} className="w-[450px] h-[300px] object-cover" />
          </div>

          <div className="flex flex-col gap-10">
            <h1 className="text-2xl text-primary-blue">
              Yapay Zeka ve Sağlık: Geleceğin Tedavi Yöntemleri Neler Olacak?
            </h1>
            <p className="text-primary-blue mt">
              Günümüzde teknolojinin ilerlemesiyle birlikte, sağlık sektörü de
              bu gelişmelerden nasibini almaktadır. Yapay zeka, sağlık alanında
              sunduğu çözümlerle tedavi yöntemlerini dönüştürmekte ve gele
            </p>
            <p className="cursor-pointer w-[300px] flex gap-2 text-primary-blue absolute right-4 bottom-8">
              Devamı için{" "}
              <span className="bg-red-400 flex justify-center items-center">
                tıklayın.
              </span>
            </p>
          </div>
        </div>

        <div className="max-w-full flex gap-28 justify-center mt-10 mx-auto relative">
          <div className="flex">
            <div className="bg-red-500 text-white w-10 flex flex-col justify-end">
              <p className="[writing-mode:vertical-rl]  flex items-center justify-center [transform:rotate(180deg)]">
                <span className="mt-5">Haber</span>
              </p>
            </div>
            <img src={Apply} className="w-[450px] h-[300px] object-cover" />
          </div>

          <div className="flex flex-col gap-10">
            <h1 className="text-2xl text-primary-blue">
              Kriptolojinin Geleceği: Yapay Zeka ve Şifreleme Entegrasyonu
            </h1>
            <p className="text-primary-blue mt">
              Teknoloji dünyasının iki öncü gücü olan kriptografi ve yapay zeka,
              birleşerek daha güçlü ve akıllı güvenlik sistemlerinin temellerini
              atmaktadır. Bu blog yazısında, kriptoloji ve yapay zeka a
            </p>
            <p className="cursor-pointer w-[300px] flex gap-2 text-primary-blue absolute right-4 bottom-8">
              Devamı için{" "}
              <span className="bg-red-400 flex justify-center items-center">
                tıklayın.
              </span>
            </p>
          </div>
        </div>

        <div className="max-w-full flex gap-28 justify-center mt-10 mx-auto relative">
          <div className="flex">
            <div className="bg-red-500 text-white w-10 flex flex-col justify-end">
              <p className="[writing-mode:vertical-rl]  flex items-center justify-center [transform:rotate(180deg)]">
                <span className="mt-5">Haber</span>
              </p>
            </div>
            <img src={Apply} className="w-[450px] h-[300px] object-cover" />
          </div>

          <div className="flex flex-col gap-10">
            <h1 className="text-2xl text-primary-blue">
              5G Teknolojisinin Evrimi: Hız, Bağlantı ve İnternetin Geleceği
            </h1>
            <p className="text-primary-blue mt">
              5G teknolojisinin hızlı evrimi, dijital dünyayı temelinden
              değiştiriyor. Hız, bağlantı kalitesi ve internet deneyimindeki bu
              devrim niteliğindeki değişiklikler, teknolojiyi daha önce hiç
              görüa
            </p>
            <p className="cursor-pointer w-[300px] flex gap-2 text-primary-blue absolute right-4 bottom-8">
              Devamı için{" "}
              <span className="bg-red-400 flex justify-center items-center">
                tıklayın.
              </span>
            </p>
          </div>
        </div>

        <div className="max-w-full flex gap-28 justify-center mt-10 mx-auto relative">
          <div className="flex">
            <div className="bg-red-500 text-white w-10 flex flex-col justify-end">
              <p className="[writing-mode:vertical-rl]  flex items-center justify-center [transform:rotate(180deg)]">
                <span className="mt-5">Haber</span>
              </p>
            </div>
            <img src={Apply} className="w-[450px] h-[300px] object-cover" />
          </div>

          <div className="flex flex-col gap-10">
            <h1 className="text-2xl text-primary-blue">
              Çevre Dostu Teknolojiler: Sürdürülebilirlik İçin Yenilikçi
              Çözümler
            </h1>
            <p className="text-primary-blue mt">
              Teknolojinin ilerlemesi, çevre sorunlarına karşı sürdürülebilir ve
              çevre dostu çözümler üretmeye odaklanıyor. Yenilikçi teknolojiler,
              doğal kaynakları koruma ve karbon ayak izini azaltma amacha
            </p>
            <p className="cursor-pointer w-[300px] flex gap-2 text-primary-blue absolute right-4 bottom-8">
              Devamı için{" "}
              <span className="bg-red-400 flex justify-center items-center">
                tıklayın.
              </span>
            </p>
          </div>
        </div> */}
      </div>

      <Footer />
    </React.Fragment>
  );
}

export default Blog;
