import React, { useState } from "react";
import { Link } from "react-router-dom";


export default function Dropdown( {menudata} ) {

  const [hovering, setHovering] = useState(null);

  const filterdata =
    menudata != null
      ? menudata.filter((item) => item.type_id === 1)
      : null;

  const titledata =
    filterdata != null
      ? filterdata.filter((item) => item.menu_belong_id === null)
      : null;

  const dataArray =
    titledata != null &&
    titledata.map((item, index) => {
      const sub = filterdata.filter(
        (subItem) => subItem.menu_belong_id === index + 1
      );

      const submenus = sub.map((subItem) => ({
        id: subItem.id,
        name: subItem.title,
        route: subItem.route,
      }));

      return {
        id: item.id,
        name: item.title,
        route: item.route,
        submenus: submenus,
      };
    });

  return (
    <div
      onMouseEnter={() => setHovering(null)}
      onMouseLeave={() => setHovering(null)}
    >
      <ul className="flex gap-4 pb-1">
        {dataArray &&
          dataArray.map((item) => (
            <li
              key={item.id}
              onMouseEnter={() => {
                setHovering(item.id);
              }}
              onClick={() => {
                setHovering(item.id);
              }}
            >
              <div aria-label="Teknopark">{item.name}</div>
            </li>
          ))}
      </ul>
      {typeof hovering === "number" && (
        <div className="absolute z-50 w-[600px] pt-[27px] ">
          <div className="bg-white px-5 shadow transition-all">
            <ul className="flex flex-col">
              {dataArray &&
                dataArray
                  .find((item) => item.id === hovering)
                  ?.submenus.map((item) => (
                    <li
                      key={item.id}
                      className="border-b border-[#D4D7E4] py-2 "
                    >
                      <Link to={item.route} className="text-black">
                        {item.name}
                      </Link>
                    </li>
                  ))}
            </ul>
          </div>
        </div>
      )}
    </div>
  );
}
