import axios from "axios";

export const getAllCompanies = async () => {
    const response = await axios.get("https://be.trakyateknopark.com.tr/api/public/companies");
    return response.data;
  };

  export const getSinglePage = async (slug) => {
    const response = await axios.get("https://be.trakyateknopark.com.tr/api/public/pages/" + slug);
    return response.data;
  };

  export const getAllMenu = async () => {
    const response = await axios.get("https://be.trakyateknopark.com.tr/api/public/menu");
    return response.data;
  };
  
  export const getAllPages = async (id) => {
    const response = await axios.get("https://be.trakyateknopark.com.tr/api/public/type/" + id);
    return response.data;
  };