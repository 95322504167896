import React from 'react'
import HeaderAll from '../../mainComponents/header-all'
import Footer from '../../mainComponents/footer'


function teknoIncubation() {
  return (

    <React.Fragment>
    <HeaderAll/>
        <div className="bg-primary-blue flex items-center h-12 w-full">
        <div className="pl-4 sm:pl-8 md:pl-16 lg:pl-32 text-xs text-white font-semibold">
          {" "}
          Anasayfa - Teknopark - Teknopark Kuluçka
        </div>
      </div>

      <div className="bg-how-apply bg-no-repeat bg-cover w-full h-60 sm:h-72 flex justify-center items-center relative">
        <div className="w-full h-full absolute bg-primary-blue/50 inset-0 z-10">
          &nbsp;
        </div>
        <p className="text-white font-light text-lg sm:text-xl text-center w-[300px] sm:w-[450px] flex flex-col justify-center items-center absolute z-10">
          Trakya Teknopark
          <span className="font-bold text-2xl sm:text-3xl">
            {" "}
            Teknopark Kuluçka
          </span>
        </p>
      </div>
  


      <div className="container mx-auto  py-11"><div className="flex flex-col gap-8"><h1 className=" text-2xl font-light text-primary-blue">Teknopark Kuluçka</h1><div className="text-justify">Ön kuluçka, bir üniversite-sanayi işbirliği yapısıdır. Farklı alanlarda eğitim görmüş veya görmekte olan bireylerin fikir ve projelerinin geliştirildiği, danışmanlık hizmetinin verildiği, kişi veya kurumlara gelecekte piyasada yer alabilmeleri için gerekli olan deneyimi kazandırma amacını taşıyan birim veya merkezlerdir. Konsepti açısından düşünülürse, ön kuluçka merkezleri teknoloji geliştirme bölgelerinde bulunan kuluçka merkezlerinin ön basamağı olarak da düşünülebilir. Ön kuluçka merkezleri teknolojik altyapı, fikir ve proje destek birimleri, markalaştırma çalışmaları gibi çalışmalarla potansiyel girişimciliği ve projelerin hayata geçişini destekleyecek yardım ve destek hizmetleri vermektedirler. Özellikle ürünlerinin pazar payını yeterince detaylı ölçme şansı olmayan veya ürün geliştirme aşamasında gerekli desteği alamayan girişimciler piyasa koşullarında finansal sıkıntılar yaşayabilmektedir. Girişimcilerin yer alacakları pazarın hacmi ve olanakları konusunda bilgi ve iş piyasası deneyim eksiklikleri gözlenmektedir. Ön kuluçka merkezlerinde pazar analizi, geliştirilecek ürünlerin her anlamda test edilmesi, projenin her aşamasında verilen danışmanlık ve eğitim hizmetleri sağlanmaktadır. Ön kuluçka merkezleri, bu hizmetler sayesinde üniversite mezuniyeti ve kuluçka merkezlerinde şirket kurma evreleri arasındaki eksiklikleri gidermede devreye girmektedir. Yeni fikirlerin ticarileşmesinde sistemi düzenleyen ön kuluçka merkezleri, projelerin başarısızlıklarını minimize ederken girişimciliği desteklemektedir.</div></div></div>





      <Footer/>
  
  </React.Fragment>
  )
}

export default teknoIncubation