import React from "react";
import { FaRegBell } from "react-icons/fa";
import { CiSearch } from "react-icons/ci";
import { BsHandbag } from "react-icons/bs";
import { Link, Outlet } from "react-router-dom";
import { IoMdArrowDropright } from "react-icons/io";
import { getSingleCompany } from "../services/companies";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { deleteToken } from "../libs/token";
function AdminLayout() {
  const navigate = useNavigate();



  async function handleLogOut() {
    const token = await deleteToken("teknotoken");
    navigate("/");
  }


  useEffect(() => {
    async function fetchData() {
      try {
        const response = await getSingleCompany(1);
        if (response) {
          throw new Error("Menu fetch failed");
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        navigate("/login");
      }
    }

    fetchData();
  }, [navigate]);
  return (
    <React.Fragment>
      <div className="max-w-screen h-screen flex bg-[#eaedf7]">
        <div className="bg-[#25233c] px-6 py-4 justify-between h-full w-64 flex flex-col gap-3 border-r-2 border-gray-500">
          <div>
            <div className="flex flex-col gap-2">
              <div className="text-center text-white  mb-4 w-full font-poppins">
                TRAKYA TEKNOPARK ADMİN PANELİ
              </div>
              <div className="border-b border-white w-full "></div>
            </div>
            <div className="mt-8">
              <ul className="text-white flex flex-col items-start gap-6 mt-2">
                <Link
                  to="/admin/pages/0"
                  className="flex items-center cursor-pointer hover:scale-110 duration-300 text-md"
                >
                  <IoMdArrowDropright size={20} />
                  <span className="ml-3 font-poppins text-sm">Sayfalar</span>
                </Link>
                <Link
                  to="/admin/pages/1"
                  className="flex items-center cursor-pointer hover:scale-110 duration-300 text-md"
                >
                  <IoMdArrowDropright size={20} />
                  <span className="ml-3 font-poppins text-sm">Haberler</span>
                </Link>
                <Link
                  to="/admin/pages/2"
                  className="flex items-center cursor-pointer hover:scale-110 duration-300 text-md"
                >
                  <IoMdArrowDropright size={20} />
                  <span className="ml-3 font-poppins text-sm">Bloglar</span>
                </Link>
                <Link
                  to="/admin/pages/3"
                  className="flex items-center cursor-pointer hover:scale-110 duration-300 text-md"
                >
                  <IoMdArrowDropright size={20} />
                  <span className="ml-3 font-poppins text-sm">
                    Yönetim Kurulu
                  </span>
                </Link>
                <Link
                  to="/admin/pages/4"
                  className="flex items-center cursor-pointer hover:scale-110 duration-300 text-md"
                >
                  <IoMdArrowDropright size={20} />
                  <span className="ml-3 font-poppins text-sm">
                    Yönetim Kadrosu
                  </span>
                </Link>
                <Link
                  to="/admin/pages/5"
                  className="flex items-center cursor-pointer hover:scale-110 duration-300 text-md"
                >
                  <IoMdArrowDropright size={20} />
                  <span className="ml-3 font-poppins text-sm">
                  Sayılarla Trakya Teknopark
                  </span>
                </Link>
                <Link
                  to="/admin/pages/6"
                  className="flex items-center cursor-pointer hover:scale-110 duration-300 text-md"
                >
                  <IoMdArrowDropright size={20} />
                  <span className="ml-3 font-poppins text-sm">
                  Firmaların Sektörel Dağılımı
                  </span>
                </Link>
                <Link
                  to="/admin/companies"
                  className="flex items-center cursor-pointer hover:scale-110 duration-300 text-md"
                >
                  <IoMdArrowDropright size={20} />
                  <span className="ml-3 font-poppins text-sm">Firmalar</span>
                </Link>

                {/* <Link
                to="/admin"
                className="flex items-center cursor-pointer hover:scale-110 duration-300 text-md"
              >
                <IoMdArrowDropright size={20} />
                <span className="ml-3 font-poppins text-sm">Ayarlar</span>
              </Link> */}
              </ul>
            </div>
          </div>
          <button onClick={handleLogOut} className="btn bg-white hover:bg-red-400 text-black"> Çıkış Yap</button>
        </div>
        <div className="w-full h-full ">
          <div  className=" sm:py-3 sm:px-10 sm:flex sm:items-center sm:justify-between sm:shadow-lg">
            <h1>ADMİN</h1>

            <div className="flex items-center gap-4">
              <FaRegBell className="cursor-pointer" />
              <CiSearch className="cursor-pointer" />
              <BsHandbag className="cursor-pointer" />

              <div className="w-6 h-6 rounded-full border-[1px]  border-black ">
                <img src="cursor-pointer" alt="" />
              </div>
            </div>
          </div>

          <div>
            <Outlet />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default AdminLayout;
