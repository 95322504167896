import "suneditor/dist/css/suneditor.min.css";
import React, { Suspense } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import { UpdatePage, GetSinglePage } from "../../../services/pages";
import { getImage, postImage } from "../../../services/images";
const SunEditor = React.lazy(() => import("suneditor-react"), {
  ssr: false,
});

export default function AdminEditPage() {
  const { realid } = useParams();
  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");
  const [menuId, setMenuId] = useState("");
  const [typeId, setTypeId] = useState("");
  const [image, setImage] = useState("");
  const [imageId, setImageId] = useState("");
  const [secId, setSecId] = useState("");
  const [category, setCategory] = useState("");
  const [slug, setSlug] = useState("");
const [fileName, setFileName] = useState("");
const [changed, setChanged] = useState("");
  const navigate = useNavigate();

  function handleImage(e) {
    setImage(e.target.files[0]);
    setChanged ("değişti")
  }
  async function fetchData() {
    const response = await GetSinglePage(realid);
    const realconent = JSON.parse(response.content);
    setTitle(response.title);
    setContent(realconent);
    setMenuId(response.menuId);
    setTypeId(response.typeId);
    setImageId(response.imageId);
    setSecId(response.id);
    setCategory(response.category);
    setFileName(response.fileName);
    const responsex = await getImage(response.imageId);
    setImage(responsex);
  }

  async function handleSubmit() {
    if (changed) {
      const formData = new FormData();
      formData.append("file", image);
      const response = await postImage(formData);
      setImageId(response.id);

    const realcontent = JSON.stringify(content);
    const data = {
      title,
      content: realcontent,
      menuId,
      typeId,
      imageId: response.id,
      category,
    };
    const responsex = await UpdatePage(data, realid);
  }
  else{
    
    const realcontent = JSON.stringify(content);
    const data = {
      title,
      content: realcontent,
      menuId,
      typeId,
      imageId,
      category,
    };
    const response = await UpdatePage(data, realid);
  }
    navigate(`/admin/pages/${typeId}`);
  }
  useEffect(() => {
    fetchData();
  }, []);
  return (
    <div className="p-5">
      <div className="w-full">
        <label className="text-[14px] font-semibold p-1"> Sayfa Başlığı </label>
        <input
          type="text"
          defaultValue={title}
          onChange={(e) => setTitle(e.target.value)}
          placeholder="Başlık Ekleyin"
          className="w-full input input-bordered mt-2 mb-4"
          style={{ backgroundColor: "#fafafa", borderRadius: "0px" }}
          isClearable
        />
      </div>
      {typeId === 1 || typeId === 2 ? (
<div>
      <img className="max-w-[200px] max-h-[200px]" src={"https://be.trakyateknopark.com.tr/uploads/" + fileName} />
      <input
        type="file"
        className="input bg-white text-black font-poppins text-sm pt-3 max-w-[400px]"
        onChange={handleImage}
        placeholder="Firma Logosu"
      /></div>
      ) : null }
      <Suspense>
        <label className="text-[14px] font-semibold p-1 mt-4"> İçerik </label>
        <p id="editorjs" style={{ marginTop: 10 }}>
          {content && (
            <SunEditor
              imageUploadHandler={() => {}}
              onImageUploadBefore={() => {}}
              onVideoUpload={() => {}}
              onVideoUploadBefore={() => {}}
              onAudioUpload={() => {}}
              onAudioUploadBefore={() => {}}
              onChange={(e) => setContent(e)}
              defaultValue={content}
              setOptions={{
                mode: "balloon",
                minHeight: "200px",
                buttonList: [["fullScreen","codeView"]],
              }}
            />
          )}
        </p>
      </Suspense>
      <button
        onClick={() => handleSubmit()}
        className="bg-blue-600 w-56 h-10 rounded-lg text-white font-poppins mt-4"
      >
        Kaydet
      </button>
    </div>
  );
}
