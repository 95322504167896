import React from "react";
import Footer from "../../mainComponents/footer.js";
import HeaderAll from "../../mainComponents/header-all/index.js";
import { getAllPages } from "../../services/public.js";
import { useState, useEffect } from "react";
function ManagementTeam() {
  const [board, setBoardData] = useState([]);
  async function fetchData() {
    const get = await getAllPages(4);
    setBoardData(get);
  }
  useEffect(() => {
    fetchData();
  }, []);

  return (
    <React.Fragment>
      <HeaderAll className="" />
      <div className="bg-primary-blue flex items-center h-12 w-full">
        <div className="pl-4 sm:pl-8 md:pl-16 lg:pl-32 text-xs text-white font-semibold">
          {" "}
          Anasayfa - Teknopark - Yönetim Kadrosu
        </div>
      </div>

      <div className="bg-tekno-firma w-full h-40 sm:h-48 md:h-60 flex justify-center items-center relative">
        <div className="w-full h-full absolute bg-primary-blue/30 inset-0 z-10">
          {" "}
          &nbsp;
        </div>
        <p className="text-white font-light text-base sm:text-lg w-full sm:w-auto flex flex-col justify-center items-center absolute z-10 px-4 text-center">
          Trakya Teknopark
          <span className="font-bold text-2xl sm:text-3xl mt-2">
            YÖNETİM KADROSU
          </span>
        </p>
      </div>

      <div>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8 justify-center items-center w-full px-4 sm:px-8 md:px-16 lg:px-32 my-8">
          {board.map((board, index) => {
            return (
              <div
                className="w-full max-w-sm mx-auto relative mt-16"
                key={index}
              >
                <div className="w-full aspect-[2/1] flex flex-col justify-center items-center bg-primary-blue-dark">
                  <div className="rounded-full flex justify-center items-center p-4 border-black border-2 bg-white absolute -top-16 w-32 h-32 overflow-hidden">
                    <img
                      className="w-full h-full object-contain"
                      src={
                        "https://be.trakyateknopark.com.tr/uploads/" +
                        board.fileName
                      }
                    />
                  </div>
                  <p className="text-white font-bold w-full px-4 flex flex-col justify-center items-center text-center mt-10 font-poppins">
                    {board.title}
                  </p>
                  <div className="text-white">
                    {" "}
                    <div
                      dangerouslySetInnerHTML={{ __html: JSON.parse(board.content) }} 
                    ></div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
        <Footer />
      </div>
    </React.Fragment>
  );
}
export default ManagementTeam;
