import React from "react";
import { PieChart, Pie, Cell } from "recharts";
import { TbPlus } from "react-icons/tb";
import { getAllPages } from "../../../services/public";
import { useState, useEffect } from "react";

export default function Numbers() {
  const [numbers, setNumbers] = useState([]);
  const [pieChart, setPieChart] = useState([]);

  async function fetchData() {
    const get = await getAllPages(5);
    setNumbers(get);
  }
  async function fetchData2() {
    const get = await getAllPages(6);
    setPieChart(get);
  }
  useEffect(() => {
    fetchData();
    fetchData2();
  }, []);


  const COLORS = ["#1e3a8a", "#3b82f6", "#93c5fd", "#60a5fa", "#bfdbfe"];

  return (
    <React.Fragment>
      <section className="container my-5 lg:my-24">
        <div className="flex flex-col gap-10 lg:grid lg:grid-cols-[33%,1fr,42%] lg:gap-0 ">
          <div className="">
            <div className="mb-8 flex items-center">
              <div className="mr-8 h-5 w-5 bg-primary-yellow-dark"></div>
              <h1 className="gap-2 text-base text-primary-yellow-dark lg:text-2xl">
                <span className="font-light">SAYILARLA</span>
                <span className="font-medium">TRAKYA TEKNOPARK</span>
              </h1>
            </div>
            <div className="grid grid-cols-2 items-start gap-10 whitespace-nowrap px-3 lg:gap-24 lg:px-0">
            {numbers.map((number, index) => (
                <div
                  className="flex flex-col items-center justify-center gap-2 text-primary-blue-light"
                  key={index}
                >
                  <div className="flex items-center">
                    <h1
                      className="text-5xl font-medium"
                      dangerouslySetInnerHTML={{
                        __html: JSON.parse(number.content),
                      }}
                    />
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="18"
                      height="18"
                      fill="none"
                    >
                      <path
                        fill="#C28E1E"
                        d="M9 18c-1.654 0-3-1.346-3-3l.053-3.053L3.018 12A3.008 3.008 0 0 1 0 9c0-1.654 1.346-3 3-3l3.053-.054L6 3.018A3.008 3.008 0 0 1 9 0c1.654 0 3 1.346 3 3l.055 2.946L15.018 6C16.654 6 18 7.346 18 9s-1.346 3-3 3l-2.945-.053L12 15.018C12 16.654 10.654 18 9 18Zm-1-8v5.018c0 .533.449.982 1 .982.551 0 1-.449 1-1v-5h5.018A.998.998 0 0 0 16 9c0-.551-.449-1-1-1h-5V3a.99.99 0 0 0-1-1c-.551 0-1 .449-1 1v5H3a.99.99 0 0 0-1 1c0 .551.449 1 1 1h5Z"
                      ></path>
                    </svg>
                  </div>
                  <h1 className="text-base font-light">{number.title}</h1>
                </div>
              ))}
            </div>
          </div>
          <div className=""></div>
          <div className="flex flex-col ">
            <div className="flex items-center ">
              <div className="mr-8 h-5 w-5 bg-primary-yellow-dark"></div>
              <h1 className="gap-2 text-base text-primary-yellow-dark lg:text-2xl">
                <span className="font-light">FİRMALARIN</span>
                <span className="font-medium"> SEKTÖREL DAĞILIMI</span>
              </h1>
            </div>
            <div className="my-5 flex w-full flex-col  items-start gap-10 lg:my-16 lg:flex-row lg:items-center">
              <div className="self-center lg:self-auto">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="173"
                  height="167"
                  fill="none"
                  viewBox="0 0 173 173"
                  className="animate-"
                >
                  <circle
                    cx="87"
                    cy="87"
                    r="68.5"
                    stroke="#eee"
                    stroke-dasharray="430.18"
                    stroke-dashoffset="0"
                    stroke-linecap="round"
                    stroke-width="35"
                  ></circle>
                  <circle
                    cx="87"
                    cy="87"
                    r="68.5"
                    stroke="#091136"
                    stroke-dasharray="430.18"
                    stroke-dashoffset="0"
                    stroke-linecap="round"
                    stroke-width="35"
                  ></circle>
                  <circle
                    cx="87"
                    cy="87"
                    r="68.5"
                    stroke="#293779"
                    stroke-dasharray="430.18"
                    stroke-dashoffset="271.15086193133504"
                    stroke-linecap="round"
                    stroke-width="35"
                  ></circle>
                  <circle
                    cx="87"
                    cy="87"
                    r="68.5"
                    stroke="#6081D1"
                    stroke-dasharray="430.18"
                    stroke-dashoffset="322.79864515635126"
                    stroke-linecap="round"
                    stroke-width="35"
                  ></circle>
                  <circle
                    cx="87"
                    cy="87"
                    r="68.5"
                    stroke="#A1C1FA"
                    stroke-dasharray="430.18"
                    stroke-dashoffset="370.1424464459494"
                    stroke-linecap="round"
                    stroke-width="35"
                  ></circle>
                  <circle
                    cx="87"
                    cy="87"
                    r="68.5"
                    stroke="#C6D9F3"
                    stroke-dasharray="430.18"
                    stroke-dashoffset="413.1822658001296"
                    stroke-linecap="round"
                    stroke-width="35"
                  ></circle>
                </svg>
              </div>
              <ul className="container flex flex-1 flex-col gap-5 self-center">
              {pieChart.map((pie, index) => (
  <li className="flex w-full items-center " key={index}>
                  <div className="h-5 w-5 rounded-full"></div>
                  <p className="ml-4 text-xl"> {pie.title}</p>
                  <div className="flex-1"></div>
                  <p className="text-xl font-semibold"     dangerouslySetInnerHTML={{
                        __html: JSON.parse(pie.content),
                      }}></p>
                </li>              ))}

              </ul>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
}
