import React, { useEffect, useState } from "react";
import { DeletePage } from "../../../services/pages";
import { getAllPages } from "../../../services/public";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";

export default function AdminPages() {
  const { id } = useParams();
  const [pages, setPagesData] = useState([]);

  async function fetchData() {
    const get = await getAllPages(id? id : 0);
    setPagesData(get);
  }

  useEffect(() => {
    fetchData();
  }, [id]);

  const handleDelete = async (id) => {
    const response = await DeletePage(id);
    fetchData();
  };

  return (
    <div className="overflow-x-auto bg-slate-200 max-h-screen text-[#B3B4B4] p-4">
      <div className="flex justify-between mt-5 ">
        <div className="text-black font-semibold text-2xl"> {id == "1" ? "HABERLER" : id == "2" ? "BLOGLAR" :  id == "3" ? "YÖNETİM KURULU":  id == "4" ? "YÖNETİM KADROSU"  : id == "5" ? "SAYILARLA TRAKYA TEKNOPARK": id == "6" ? "FİRMALARIN SEKTÖREL DAĞILIMI"  : "SAYFALAR"}</div>
        <Link to="/admin/pages/new">
          <button className="text-black bg-gray-300 rounded-full px-4 py-2">
            <span className="font-bold">+</span> Yeni Ekle
          </button>
        </Link>
      </div>

      <div className="overflow-hidden rounded-lg shadow-lg mt-6">
        <table className="min-w-full divide-y divide-gray-200">
          <thead className="bg-gray-50">
            <tr>
              <th
                scope="col"
                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                Sayfa Adı{" "}
              </th>
              <th
                scope="col"
                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                Düzenle
              </th>
              <th
                scope="col"
                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                Sil
              </th>
            </tr>
          </thead>

          <tbody className="bg-white divide-y divide-gray-200">
            {pages.map((page, index) => (
              <tr key={index} className="">
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                  {page.title}
                </td>

                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                  <Link to={"/admin/pages/" + (id?id:0) + "/edit/" + page.id}>
                    <button className="text-white bg-blue-500 hover:bg-blue-700 px-4 py-2 rounded-full">
                      Düzenle
                    </button>
                  </Link>
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                  <button
                    onClick={() => handleDelete(page.id)}
                    className="text-white bg-red-500 hover:bg-red-700 px-4 py-2 rounded-full"
                  >
                    Sil
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}
