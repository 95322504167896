import axiosImage from "../libs/axiosImage";

export const postImage = async (formData) => {
    const response = await axiosImage.post("/images/upload", formData);
    return response.data;
  };
  
export const getImage = async (id) => {
  const response = await axiosImage.get("/images/" + id);
  return response.data;
};


 