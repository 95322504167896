import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { getSinglePage } from "../../../services/public";
import Footer from "../../../mainComponents/footer";
import HeaderAll from "../../../mainComponents/header-all";

function InsideNews() {
  const { id } = useParams();
  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");
const [image, setImage] = useState("");
  async function fetchData() {
    const response = await getSinglePage(id);
    const realContent = JSON.parse(response.content);
    setTitle(response.title);
    setImage(response.fileName);
    setContent(realContent);
  }

  useEffect(() => {
    fetchData();
  }, [id]);

  return (
    <React.Fragment>
      <HeaderAll />
      <div className="bg-primary-blue flex items-center h-12 w-full px-4 md:px-8 lg:px-16 text-xs text-white font-semibold">
        <div className="flex-1">
          Anasayfa - Teknopark - Firmalarımız
        </div>
      </div>

      <div className="bg-how-apply bg-no-repeat bg-cover w-full h-60 md:h-72 lg:h-80 flex justify-center items-center relative">
        <div className="w-full h-full absolute bg-primary-blue/50 inset-0 z-10" />
        <p className="text-white font-light text-lg md:text-xl lg:text-2xl xl:text-3xl w-full text-center absolute z-20 px-4">
          Trakya Teknopark
          <span className="font-bold text-xl md:text-2xl lg:text-3xl xl:text-4xl block mt-2">{title}</span>
        </p>
      </div>

      <div className="w-full flex flex-col p-4 md:p-6 lg:p-8 xl:p-12 gap-4 md:gap-6 lg:gap-8">
      <img
          src={"https://be.trakyateknopark.com.tr/uploads/" + image}
          className="w-full h-60 sm:h-72 object-contain"
        />
        <div
          className="prose prose-sm md:prose-base lg:prose-lg xl:prose-xl mx-auto"
          dangerouslySetInnerHTML={{ __html: content }}
        />
      </div>

      <Footer />
    </React.Fragment>
  );
}

export default InsideNews;
