import React from 'react';
import { MapContainer, TileLayer, Marker } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L, {Icon} from 'leaflet';

delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
  iconRetinaUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-icon-2x.png',
  iconUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-icon.png',
  shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-shadow.png',
});
const customIcon = new Icon({
  iconUrl: "https://cdn-icons-png.flaticon.com/512/447/447031.png",
  iconAnchor: [19, 38],
  iconSize: [38, 38],
});
const ContactMapComponent = () => {
  const position = [41.6680256123735, 26.575058677564318]; // Coordinates for Edirne

  return (
    <div className="container mx-auto grid grid-cols-1 md:grid-cols-3 gap-8 bg-gray-100 mb-10">
      {/* Contact Form */}
      <div className="bg-contact-form-bg bg-cover text-white p-8 shadow-lg mx-5">
        <h2 className="text-3xl justify-start font-medium mb-6">Bizimle iletişime geçin!</h2>
        <form>
          <div className="mb-4">
            <label className="text-sm font-light text-white">Adınız</label>
            <input type="text" placeholder="" className="w-full border-b border-white bg-transparent p-2 outline-none" />
          </div>
          <div className="mb-4">
          <label className="text-sm font-light text-white">E-posta</label>
            <input type="email" placeholder="" className="w-full border-b border-white bg-transparent p-2 outline-none" />
          </div>
          <div className="mb-4">
          <label className="text-sm font-light text-white">Telefon numarası</label>
            <input type="tel" placeholder="" className="w-full border-b border-white bg-transparent p-2 outline-none" />
          </div>
          <div className="mb-6">
          <div className="text-sm font-light text-white">Mesajınız</div>
            <textarea placeholder="" rows="4" className=" border-b w-full border-white bg-transparent p-2 outline-none"></textarea>
          </div>
          <div className='flex w-full justify-end'>
          <button type="submit"               className="flex items-center gap-4 self-end rounded-full bg-white/10 px-5 py-3 text-xs font-light text-white"
          >
            Gönder →
          </button></div>
        </form>
      </div>

      {/* Map and Address */}
      <div className=' col-span-1 md:col-span-2'>
        <h3 className="text-xl font-bold mb-4 mt-7  ">KONUM</h3>
        <p className="mb-4 text-sm text-primary-blue-dark">
          Trakya Teknopark<br />
          Trakya Üniversitesi Ayşekadın Yerleşkesi<br />
          Şükrüpaşa Mahallesi, Zübeyde Hanım Caddesi<br />
          22100, Edirne
        </p>
        <div className="h-96 rounded-lg overflow-hidden">
          <MapContainer center={position} zoom={15} style={{ height: '100%', width: '100%' }}>
            <TileLayer
        url="https://{s}.tile.jawg.io/jawg-light/{z}/{x}/{y}{r}.png?access-token=TybMJrxh1wzBUyjtvYTI2gKMofuMMW9hpbSbIvXi4l88NfxWMbAYrAKTEIdqt3tW"
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            />
            <Marker position={position} icon={customIcon} />
          </MapContainer>
        </div>
      </div>
    </div>
  );
};

export default ContactMapComponent;