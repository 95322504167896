import React, { useEffect, useParams, useState } from "react";
import HeaderAll from "../../mainComponents/header-all";
import Footer from "../../mainComponents/footer";
import Apply from "../../companiesImages/nasil-basvurulur-bg.png";
import { Link, Outlet } from "react-router-dom";
import { getAllPages } from "../../services/public";

function News() {
  const [news, setNews] = useState([]);
  async function fetchData() {
    const get = await getAllPages(1);

    setNews(get);
  }
  useEffect(() => {
    fetchData();
  }, []);

  return (
    <React.Fragment>
      <HeaderAll />
      <div className="bg-primary-blue  flex items-center h-12 w-full">
        <div className="pl-32 text-xs text-white font-semibold">
          Anasayfa - Teknopark - Haberler
        </div>
      </div>

      <div className="bg-blogPage w-full h-60 flex justify-center items-center relative">
        <div className="w-full h-full absolute bg-primary-blue/30 inset-0 z-10">
          &nbsp;
        </div>
        <p className="text-white font-light text-2xl w-[450px] flex flex-col justify-center items-center absolute z-10">
          Trakya Teknopark
          <span className="font-bold text-4xl flex">Haberler</span>
        </p>
      </div>

      <div className="flex flex-col gap-10 px-20 py-4  ">
        {news.map((news, index) => (
          <div key={index} className="max-w-full flex gap-28  mt-10  mx-5 relative">
            <div className="flex">
              <div className="bg-yellow-500 text-white w-10 flex flex-col justify-end">
                <p className="[writing-mode:vertical-rl]  flex items-center justify-center [transform:rotate(180deg)]">
                  <span className="mt-5 text-2xl">Duyuru</span>
                </p>
              </div>
              <img
                src={
                  "https://be.trakyateknopark.com.tr/uploads/" + news.fileName
                }
                className="w-[350px] h-[300px] object-cover"
              />
            </div>

            <div className="flex flex-col gap-10">
              <h1 className="text-2xl text-primary-blue">{news.title}</h1>
              <p className="text-primary-blue mt">
              <div
                  dangerouslySetInnerHTML={{
                    __html:
                      JSON.parse(news.content)
                        .split(" ")
                        .slice(0, 10)
                        .join(" ") + "...",
                  }}
                ></div>              </p>
              <Link
                to={news.slug}
                className="cursor-pointer w-[300px] flex gap-2 text-primary-blue absolute right-4 bottom-8"
              >
                Devamı için{" "}
                <span className="bg-yellow-400 flex justify-center items-center">
                  tıklayın.
                </span>
              </Link>
            </div>
          </div>
        ))}
      </div>

      <Footer />
    </React.Fragment>
  );
}

export default News;
