import * as React from "react";
import { memo } from "react";

const SvgComponent = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={15}
    height={18}
    viewBox="0 0 15 18"
    fill="none"
    {...props}
  >
    <path
      stroke="#fff"
      strokeLinecap="round"
      strokeWidth={1.5}
      d="M7.5 17c1.68 0 3.29-.843 4.478-2.343 1.188-1.5 1.855-3.535 1.855-5.657s-.667-4.157-1.855-5.657C10.791 1.843 9.18 1 7.5 1"
    />
    <path
      stroke="#fff"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M1.167 9h7.916m0 0L6.708 6m2.375 3-2.375 3"
    />
  </svg>
);

const EnterIcon = memo(SvgComponent);
export default EnterIcon;
