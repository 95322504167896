import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { getSinglePage } from "../../../services/public";
import Footer from "../../../mainComponents/footer";
import HeaderAll from "../../../mainComponents/header-all";
function InsideBlogs() {
  const { id } = useParams();
  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");
  const [image, setImage] = useState("");

  async function fetchData() {
    const response = await getSinglePage(id);
    const realconent = JSON.parse(response.content);
    setImage(response.fileName);
    setTitle(response.title);
    setContent(realconent);
  }

  useEffect(() => {
    fetchData();
  }, []);
  return (
    <React.Fragment>
      <HeaderAll />
      <div className="bg-primary-blue flex items-center h-12 w-full">
        <div className="pl-4 sm:pl-8 lg:pl-32 text-xs text-white font-semibold">
          Anasayfa - Teknopark - Firmalarımız
        </div>
      </div>

      <div className="bg-how-apply bg-no-repeat bg-cover w-full h-60 sm:h-72 flex justify-center items-center relative">
        <div className="w-full h-full absolute bg-primary-blue/50 inset-0 z-10"></div>
        <p className="text-white font-light text-lg sm:text-xl lg:text-2xl w-[300px] sm:w-[450px] flex flex-col justify-center items-center absolute z-10 text-center">
          Trakya Teknopark
          <span className="font-bold text-2xl sm:text-3xl lg:text-4xl">
            {title}
          </span>
        </p>
      </div>

      <div className="w-full flex flex-col p-4 sm:p-10 lg:p-20 gap-8 lg:gap-10">
        <img
          src={"https://be.trakyateknopark.com.tr/uploads/" + image}
          className="w-full h-60 sm:h-72 object-cover"
        />
        <div dangerouslySetInnerHTML={{ __html: content }}></div>
      </div>

      <Footer />
    </React.Fragment>
  );
}

export default InsideBlogs;
