import Numbers from './components/numbers';
import Maps from '../../mainComponents/maps';
import Footer from '../../mainComponents/footer';
import MainSlider from './components/mainSlider';
import Header from '../../mainComponents/header';
import News from './components/news/news';
import Shortcuts from './components/shortcuts';
export default function Home() {
  return (
    <div>
      <Header />
      <MainSlider />
      <News />
      <Shortcuts />
      <Numbers />
      <Maps />
      <Footer />
    </div>
  );
}