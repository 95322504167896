import React, { useEffect, useState } from "react";
import { deleteCompanies } from "../../../services/companies";
import { getAllCompanies } from "../../../services/public";
import { Link } from "react-router-dom";

export default function AdminCompanies() {
  const [companies, setCompaniesData] = useState([]);
  async function fetchData() {
    const get = await getAllCompanies();
    setCompaniesData(get);
  }
  useEffect(() => {
    fetchData();
  }, []);

  const handleDelete = async (id) => {
    const response = await deleteCompanies(id);
    fetchData();
  };

  return (
    <div className="overflow-x-auto bg-slate-200 max-h-screen text-[#B3B4B4] p-4">
      <div className="flex justify-end mt-5 px-4">
        <Link to="/admin/companies/new">
          <button className="text-black bg-gray-300 rounded-full px-4 py-2">
            <span className="font-bold">+</span> Yeni Hizmet Ekle
          </button>
        </Link>
      </div>

      <div className="overflow-hidden rounded-lg shadow-lg mt-6">
        <table className="min-w-full divide-y divide-gray-200">
          <thead className="bg-gray-50">
            <tr>
              <th
                scope="col"
                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                Firma Adı{" "}
              </th>
              <th
                scope="col"
                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                Firma Logosu
              </th>
              <th
                scope="col"
                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                Düzenle
              </th>
              <th
                scope="col"
                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                Sil
              </th>
            </tr>
          </thead>

          <tbody className="bg-white divide-y divide-gray-200">
            {companies.map((company, index) => (
              <tr key={index} className="">
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                  {company.name}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                  {company.logoUrl}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                  <Link to={"/admin/companies/" + company.id}>
                    <button className="text-white bg-blue-500 hover:bg-blue-700 px-4 py-2 rounded-full">
                      Düzenle
                    </button>
                  </Link>
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                  <button
                    onClick={() => handleDelete(company.id)}
                    className="text-white bg-red-500 hover:bg-red-700 px-4 py-2 rounded-full"
                  >
                    Sil
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}
