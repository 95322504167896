import { putCompanies, getSingleCompany } from "../../../services/companies";
import { useNavigate, useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import { postImage } from "../../../services/images";

export default function EditCompany() {
  const { id } = useParams();
  const [name, setName] = useState("");
  const navigate = useNavigate();
  const [image, setImage] = useState("");
  const [imageId, setImageId] = useState("");
  const [fileName, setFileName] = useState("");

  function handleImage(e) {
    setImage(e.target.files[0]);
  }
  async function fetchData() {
    const response = await getSingleCompany(id);

    setName(response.name);
    setImageId(response.image.id);
    setFileName(response.image.fileName);
  }

  async function handleSubmit() {
    if (image) {
      const formData = new FormData();
      formData.append("file", image);
      const response = await postImage(formData);
      setImageId(response.id);

      const data = {
        name,
        imageId: response.id,
      };
      const responsex = await putCompanies(data, id);
    }
    else{
      
      const data = {
        name,
        imageId,
      };
      const responsex = await putCompanies(data, id);
    }


    navigate(0);
  }
  useEffect(() => {
    fetchData();
  }, []);
  return (
    <div className="flex flex-col justify-center mt-12 space-y-4 px-5 ">
      <input
        type="text"
        className="input bg-white text-black font-poppins"
        defaultValue={name}
        onChange={(e) => setName(e.target.value)}
        placeholder="Firma Adı"
      />
      <img className="max-w-[200px] max-h-[200px]" src={"https://be.trakyateknopark.com.tr/uploads/" + fileName} />
      <input
        type="file"
        className="input bg-white text-black font-poppins text-sm pt-3 max-w-[400px]"
        onChange={handleImage}
        placeholder="Firma Logosu"
      />
      <button
        onClick={handleSubmit}
        className="bg-blue-600 w-56 h-10 rounded-lg text-white font-poppins"
      >
        Kaydet
      </button>
    </div>
  );
}
